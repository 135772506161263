/*============================================================================
  FORMS
==============================================================================*/

form {
  margin: 0;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
  font-size: 18px;
}

button, input, select, textarea, .button {
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
  transition: $transitionDefault;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

// When having a body font-size < 16px
// Prevent zoom on touch devices in active inputs
// @media #{$maxMedium} {
//   input, textarea {
//     font-size: 16px;
//   }
// }

label, .label, input, select, textarea {
  @include default-font();
  vertical-align: middle;
  color: $black;
}

label, .label {
  display: block;
  margin-bottom: 8px;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
  border: none;
}

input[type="checkbox"], input[type="radio"] {
  cursor: pointer;
}

input[type="text"], input[type="search"], input[type="password"],
input[type="email"], input[type="tel"], input[type="date"], textarea, select {
  padding: 6px;
  margin-bottom: 2px;
  display: block;
  background: #fff;
  height: 35px;
  line-height: inherit;
  width: 100%;
  border: 1px solid $gray;

  &:focus {
    outline: 0;
    border-color: $grayDark;
  }
}

input[type="email"] {
  display: block;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  width: 100%;
  height: 185px;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding: 0;
  margin: 3px 0;
  *margin-top: 0;
  *height: 13px;
  *width: 13px;
  line-height: normal;
  border: none;
}

input[type="file"] {
  background-color: #fff;
  padding: initial;
  border: initial;
  line-height: initial;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

.arrowed-link {
@include clearfix;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  @include fs(13);
  font-weight: 600;
  @include optimize-font;
  display: inline-block;
  color: $darkBlue;

  &:hover, &:focus, &:active {
    color: $accent;

    &:after {
     transform: translateX(10px);
    }
  }

  &:after {
    @extend .icon;
    @extend .icon-long-arrow;
    float: right;
    content: "\e918";
    margin-top: 2px;
    margin-left: 10px;
    @include fs(8);
    transition: $transitionDefault;
  }
}

.arrowed-link--back {
  color: $darkBlue;

  &:after {
    float: left;
    transform: rotate(180deg);
    margin-left: 0;
    margin-right: 10px;
  }

  &:hover, &:focus, &:active {
    &:after {
      transform: rotate(180deg) translateX(10px);
      margin-left: 0px;
    }
  }
}

// Buttons
button, input[type="reset"], input[type="submit"], .button {
  float: left;
  display: block;
  clear: both;
  transition: $transitionDefault;
  outline: 0;
  line-height: 1.2em;
  padding: 15px 20px;
  margin: 10px 0 0;
  background-color: $darkBlue;
  text-decoration: none;
  color: $white;
  text-transform: uppercase;
  border-radius: 0px;
  @include fs(14);
  letter-spacing: 0.15em;
  font-weight: 600;
  @include optimize-font;

  i {
    margin: -5px 6px 0 5px;
  }

  &:hover, &:focus, &:active {
    background-color: $accent;
    color: $white;
  }

  &:active {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    transform: translateY(1px);
  }

  &::-moz-focus-inner {
    border: 0;
  }
}

.button--skew {
  position: relative;
  border: 1px solid $darkBlue;
  border-left: none;
  display: inline-block;
  float: none;
  color: $white;
  z-index: 11;
  margin-left: 20px;
  background-color: $darkBlue;

  &:before {
    content: '';
    border: 1px solid $darkBlue;
    background-color: $darkBlue;
    border-right: none;
    width: 20px;
    height: calc(100% + 2px);
    margin-top: -1px;
    transform: skew(-20deg);
    position: absolute;
    left: 0;
    top: 0;
    margin-left: -10px;
    z-index: -1;
    transition: $transitionDefault;
  }

  &:hover, &:focus, &:active {
    background-color: $accent;
    border-color: $accent;

    &:before {
      background-color: $accent;
      border-color: $accent;
    }
  }

  &.button--skew-right {
    padding-left: 20px;
    padding-right: 10px;
    margin-right: 20px;
    float: none;
    margin-left: 0;

    &:before {
      left:auto;
      right: 0;
      margin-left: 0;
      margin-right: -10px;
      transform: skew(-20deg);
    }
  }
}

.button--white {
  border-color: $darkBlue;
  color: $darkBlue;
  background-color: $white;
  &:before {
    background-color: $white;
  }

  &:hover, &:focus, &:active {
    border-color: $accent;
    color: $white;
    background-color: $accent;
    &:before {
      background-color: $accent;
      border-color: $accent;
    }
  }
}


.button__content {
  //display: block;
  //transform: skew(20deg);
  /*background-color: $white;
  position: relative;
  z-index: 99;
  width: 100%;
  height: 100%;*/
}

button, input[type="text"], input[type="submit"], input[type="button"], input[type="reset"],
input[type="email"], input[type="tel"], textarea {
  -webkit-appearance: none;
}

button, input[type="reset"], input[type="submit"], .button, a.button, select {
  &:focus {
    outline: 0;
    *outline: inherit;
    box-shadow: 0 0 1px 1px rgba(200, 200, 200, 0.1);
  }
}

.button-social {
  margin: 10px;
  display: block;
  border-radius: 0;
  position: relative;

  i {
    color: $darkBlue;
    @include fs(20);
    position: absolute;
    display: block;
    top: 50%;
    margin: 0;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: $transitionDefault;

    &.icon-twitter {
      @include fs(16);
    }
  }

  &:hover, &:focus, &:active {
    i {
      color: $accent;
      margin: 0;
    }
  }
}

.button--scroll {
  position: relative;
  width: 40px;
  height: 40px;
  background: transparent;
  display: block;
  float: none;
  margin: 0 auto;
  top: 25px;
  text-align: center;

  &:before {
    @extend .icon;
    content: "\e914";
    @include fs(16);
    color: $accent;
    position: absolute;
    margin-left: 2px;
    margin-top: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover, &:focus {
    background: transparent;
  }

  @media #{$medium} {
    top: 50px;
  }
}

::placeholder {
  color: #969696;
  font-style: normal;
  font-weight: 400;
}

// Hide clear text field ie 10+
::-ms-clear {
  display: none;
}

// Remove the rounded corners
input[type="search"] {
  -webkit-appearance: none;
}

// Hide the cancel button
::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

// Hide the magnifying glass
::-webkit-search-results-button {
  -webkit-appearance: none;
}

::-webkit-textfield-decoration-container {
}

// Hide number input spinner
::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

// Style file upload button
::-ms-browse, ::-webkit-file-upload-button {
}

// Forms
form {
  .row {
    margin-bottom: 0;
  }
}

fieldset {
  width: auto;

  p, ul, li {
    @include clearfix;
  }

  ul {
    margin-left: -20px;

    li {
      list-style: none;
      background: none;
      list-style-image: none;
      margin: 0 0 15px;
      float: left;
      width: 100%;
      padding: 0 0 0 20px;

      &.error {
        input {
          border-color: $error;
        }
      }

      &.space {
        margin-top: 25px;
      }

      @media #{$medium} {
        &.one-half {
          width: 50%;
        }
        &.one-fourth {
          width: 25%;
        }
      }
    }
  }
}

.buttons {
  @include clearfix;
  button, input[type="reset"], input[type="submit"], .button, a.button {
    float: left;
    clear: none;
    margin-right: 15px;

    &.right {
      float: right;
      margin-right: 0;
      margin-left: 15px;
    }
  }
}

.a-c {
  .button, a.button, button {
    float: none;
    clear: none;
    display: inline-block;
  }
}



// Recaptcha
.grecaptcha-badge {
  display: none;
}

.floating-label-container {
  position: relative;
  display: inline-block;vertical-align: top;
  width: 100%;
  font-weight: 600;

  label {
    @include fs(14);
    font-weight: 600;
    position: absolute;
    top:0; left: 0; transform: translate(0,0);
    white-space: nowrap; width: auto;
    opacity: 0; visibility: hidden;
    transition: all 0.5s;

    &.active {
      display: block; visibility: visible; opacity: 1; @include fs(9);
      transform:translate(1em, 0.5em);


    }
  }

  input, textarea {
    border: 1px solid $darkBlue;
    font-weight: 600;
    color: $darkBlue;
    padding-left: 15px;
  }

  input {
    height: 45px;
    width: 100%;


    &.active {
      padding-top: 15px;
      border-bottom: 3px solid $darkBlue;
    }
  }

  textarea.active {
    padding-top: 25px;
  }

  &.floating-label-container--above {
    margin-top: 1.4em;
    label.active { transform:translate(0em, -2em);  }
    input { height: 35px; &.active { padding-top: 6px;}}
  }

  &.floating-label-container--error {
    input {
      border: 1px solid red;
    }
  }

  select {
    border: 1px solid #006A8E;
    color: #969696;
    padding-left: 15px;
    height: 45px;
    width: 100%;
  }
}

.accept_data-wrap {
  display: flex;
  align-items: flex-start;

  label,a {
    color: #9A9A9A;
  }


  label, input[type="checkbox"] {
    display: inline-block;
  }
  label {
    margin-left: 20px;
    @include lh(20);
  }

  input[type="checkbox"] {
    position: relative;
    -webkit-appearance: none;
    width: 20px;
    padding: 0;
    margin: 0;flex-shrink: 0;
    height: 20px;
    border: 1px solid $darkBlue;


    &:after {
      content: '';
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      top: 0;
      margin-top: 2px;
      margin-left: 2px;
      left: 0;


    }
    &:checked {


      &:after {
        background-color: $darkBlue;
      }

    }
  }
}

.accept_data_saveformError.formError {

  .formErrorArrow {
    margin-left: 15px;
  }
}
