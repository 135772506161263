/*============================================================================
  VARIABLES
==============================================================================*/

// Grays
$black: #4a4a4a;
$grayDark: #777777;
$gray: #bebebe;
$grayMiddle: #a0a0a0;
$grayLight: #a0a0a0;
$white: #fff;
$pink: #ffe9e8;
$darkBlue: #006a8e;

// Accent colors
//#3EB1C8
$accent: #3eb1c8;
//$accentHover: darken($accent, 10%);
$accentHover: $darkBlue;

$accentLight: lighten($accent, 15%);

// Links
$linkColor: $black;
$linkColorHover: $grayDark;

// States
$error: #f1062c;
$errorBg: lighten($error, 50%);

$success: #39963a;
$successBg: lighten($success, 55%);

// Selection
$selection: lighten($accent, 40%);

// Easing
$easeLinear: cubic-bezier(0, 0, 1, 1);
$easeIn: cubic-bezier(0.42, 0, 1, 1);
$easeOut: cubic-bezier(0, 0, 0.58, 1);
$easeInOut: cubic-bezier(0.42, 0, 0.58, 1);

// Transitions
$transitionDefault: 0.25s $easeOut;

// Sizes
$docFontSize: 10;

$subnavMaxHeight: 700px;

// Grid
$gutter: 20px !default;

// Would you like push and pull classes enabled?
$push: true !default;
$pull: false !default;

// Mediaqueries
// If you want more breakpoints, add a nummeric suffix (e.g huge-2)
$smallSize: 480px;
$mediumSize: 768px;
$largeSize: 1024px;
$largerSize: 1200px;
$hugeSize: 1400px;
$massiveSize: 1680px;

$navBreakSize: $largeSize;

$small: "(min-width: #{$smallSize})";
$mobile: "(max-width: #{$smallSize})";
$maxMedium: "(max-width: #{$mediumSize})";
$medium: "(min-width: #{$mediumSize})";
$maxLarge: "(max-width: #{$largeSize})";
$large: "(min-width: #{$largeSize})";
$larger: "(min-width: #{$largerSize})";
$huge: "(min-width: #{$hugeSize})";
$massive: "(min-width: #{$massiveSize})";

$nav: "(min-width: #{$navBreakSize})";

$between_medium_and_large: "(min-width: #{$mediumSize}) and (max-width: #{$largeSize});";

// Mobile mediaqueries
$maxMedium: "(max-width: 766px)";
$maxNav: "(max-width: #{$navBreakSize})";

$footerHeightMedium: 400px;
$footerHeightLarge: 300px;

// Define your breakpoints. The first value is the prefix that shall be used for
// your classes (e.g. `.medium--one-half`), the second value is the media query
// that the breakpoint fires at.
$breakpoints: (
  "small" "#{$small}",
  "medium" "#{$medium}",
  "large" "#{$large}",
  "larger" "#{$larger}",
  "huge" "#{$huge}",
  "massive" "#{$massive}"
) !default;

// Define which namespaced breakpoints you would like to generate for each of
// widths, push and pull. This is handy if you only need pull on, say, large, or
// you only need a new width breakpoint at mobile sizes. It allows you to only
// compile as much CSS as you need.
$breakpoint-has-widths: ("medium", "large") !default;
$breakpoint-has-push: ("medium") !default;
$breakpoint-has-pull: ("medium") !default;

// Init mediaqueries for ordering inside carabiner
.init-mediaqueries {
  @media #{$small} {
    margin: 0;
  }
  @media #{$medium} {
    margin: 0;
  }
  @media #{$large} {
    margin: 0;
  }
  @media #{$larger} {
    margin: 0;
  }
  @media #{$huge} {
    margin: 0;
  }
  @media #{$massive} {
    margin: 0;
  }
}
