/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */
/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */
/* =============================================================================
   IMPORTS
   ========================================================================== */
// CSS Reset
@import "inc/reset";
// Core
@import "inc/vars";
@import "inc/mixins";
@import "inc/animations";
//@import "inc/custom-animations";
// Styled patterns and elements
@import "inc/grid";
@import "inc/type";
@import "inc/tables";
@import "inc/forms";
// Plugins
@import "plugins/validation-engine";
@import "plugins/fancybox";

/* =============================================================================
   LAYOUT
   ========================================================================== */

html {
  font-size: 62.5%;
}

body {
  @include default-font;
  color: $grayDark;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

.page-wrap {
  position: relative;

  @media #{$medium} {
    min-height: 100vh;
    z-index: 2;
  }
}

.container {
  position: relative;
  max-width: 1480px;
  margin: 0 auto;
  padding: 0 20px;

  @media #{$large} {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.container--large {
  max-width: 1800px;
  padding-left: 0;
  padding-right: 0;
}

.container--static {
  position: static;
}

// Typekit async loading (all elements using $ typekit font)
.wf-loading {
  overflow: hidden;
  body {
    visibility: hidden;
  }
}

::selection {
  background-color: $selection;
  color: inherit;
}

/* =============================================================================
   HELPERS
   ========================================================================== */

// Icons
.icon {
  display: inline-block;
  vertical-align: middle;
  transition: $transitionDefault;
}

.icon-hamburger,
.icon-hamburger:before,
.icon-hamburger:after {
  width: 24px;
  position: absolute;
  left: 0;
  height: 1px;
  border-top: 3px solid $accent;
  transition: $transitionDefault;
}

.icon-hamburger {
  position: relative;
  &:before,
  &:after {
    content: "";
    margin-left: 0;
  }
  &:before {
    margin-top: 3px;
  }
  &:after {
    margin-top: 9px;
  }
}

.dib.dib {
  display: inline-block;
}

// Exceptions: only add what you need!
.mb0.mb0 {
  margin-bottom: 0;
}

.pb0.pb0 {
  padding-bottom: 0;
}

.mb20.mb20 {
  margin-bottom: 20px;
}

.mb40.mb40 {
  margin-bottom: 40px;
}

.mb60.mb60 {
  margin-bottom: 60px;
}

.mb80.mb80 {
  margin-bottom: 40px;
  @media #{$medium} {
    margin-bottom: 80px;
  }
}

.mb140.mb140 {
  margin-bottom: 140px;
}

// .mt0.mt0 { margin-top: 0; }
// .mt20.mt20 { margin-top: 20px; }
// .mt40.mt40 { margin-top: 40px; }

// .ttu { text-transform: uppercase; }
// .ttl { text-transform: lowercase; }

.a-c.a-c {
  text-align: center;
}

.a-r.a-r {
  text-align: right;
}

.small--mb20 {
  margin-bottom: 20px;

  @media #{$nav} {
    margin-bottom: 0px;
  }
}

.mb-gutter {
  margin-bottom: 30px;
}

.mobile--mb-gutter {
  @media #{$maxMedium} {
    margin-bottom: 30px;
  }
}

.medium--mb-dubble-gutter {
  @media #{$medium} {
    margin-bottom: 60px;
  }
}

// Google maps mw
.gm-style img {
  max-width: inherit;
}

// Responsive video
.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Equal height
.equal-h {
  display: flex;
  //overflow: hidden;
  flex-wrap: wrap;

  > * {
    display: flex;
  }

  &:before,
  &:after {
    display: none;
  }
}

// Equal height medium
@media #{$medium} {
  .medium--equal-h {
    display: flex;
    //overflow: hidden;
    flex-wrap: wrap;

    > * {
      display: flex;
    }

    &:before,
    &:after {
      display: none;
    }
  }
}

html.no-flexbox {
  .equal-h {
    height: 100%;
    > div {
      float: left;
    }
  }
}

@media #{$medium} {
  html.no-flexbox {
    .medium--equal-h {
      height: 100%;
      > div {
        float: left;
      }
    }
  }
}

.grid--v-centered {
  @media #{$medium} {
    align-items: center;
  }

  > .grid__item {
    flex-shrink: 0;
  }
}

// Vertical align (not recommended)
// .va-wrap, .va-m, .va-b { display: table; width: 100%; height: 100%; }
// .va-wrap { table-layout: fixed; }
// .va-m, .va-b { display: table-cell; }
// .va-m { vertical-align: middle; }
// .va-b { vertical-align: bottom; }

// Block link
.block-link {
  position: relative;
  cursor: pointer;
}

.block-link__target {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/* =============================================================================
   HEADER
   ========================================================================== */

.page-header {
  @include clearfix;
  padding: 20px 0;

  @media #{$medium} {
    padding: 15px 0;
  }
}

.page-header__logo {
  text-decoration: none;
  @include opacity(100);
  float: left;

  img {
    display: block;
    width: 130px;

    @media #{$medium} {
      width: 175px;
      margin-top: 10px;
    }

    @media #{$nav} {
      margin-top: 20px;
    }

    @media #{$large} {
      width: 250px;
    }
  }

  &:hover,
  &:focus {
    @include opacity(80);
  }
}

.main-nav {
  text-transform: uppercase;
  @include optimize-font;
  color: $black;
  @include clearfix;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;

  @media #{$medium} {
    float: right;
    width: auto;
    display: block;
    overflow: visible;

    a:not(.button) {
      border-top: 0;
      padding: 0;
    }
  }
}

.mobile-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;

  @media #{$nav} {
    display: none;
  }

  .main-nav__trigger {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
  }
}

.mobile-header__logo {
  text-align: center;
  margin-right: 20px;

  img {
    display: inline-block;
    width: 175px;
  }

  @media #{$nav} {
    display: none;
  }
}

.main-nav__wrap__item {
  @include fs(14);
  z-index: 999;
  font-weight: 500;
  text-transform: uppercase;
  color: $grayMiddle;
  letter-spacing: 0.1em;
  position: relative;

  &:hover {
    @media #{$nav} {
      .main-nav__subnav {
        max-height: $subnavMaxHeight;
        border: 1px solid rgba($grayLight, 0.25);
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }

  @media #{$nav} {
    float: left;
    margin: 0;
    padding: 0 10px 10px 10px;
    border: none;
  }

  @media #{$larger} {
    padding: 0 20px 10px 20px;
  }

  > a:not(.button) {
    text-decoration: none;
    color: $grayDark;
    padding: 10px 0;
    display: block;

    @media #{$nav} {
      padding: 0;
      border-top: none;
      border-bottom: none;
    }
  }

  > a:not(.button):hover,
  > a:not(.button):focus,
  &.active > a {
    color: $accent;
    font-weight: 600;
  }

  a:after {
    content: attr(data-title);
    visibility: hidden;
    display: block;
    height: 0;
    overflow: hidden;
    font-weight: 600;
  }
}

.main-nav__wrap__social-button {
  display: inline-block;
  margin-bottom: 0px;

  a.button-social {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0 5px;

    i {
      @include fs(16);
      padding: 3px;
    }
  }
}

.main-nav__wrap__item--button {
  padding-right: 0;

  .button {
    margin-top: 0;
    color: $white;
    margin-left: 10px;
    @include fs(14);
  }
}

.mobile-order-cta {
  display: block;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;

  @media #{$nav} {
    display: none;
    pointer-events: none;
  }
}

.main-nav__trigger {
  display: block;
  padding: 12px 20px 12px 55px;
  background-color: transparent;
  color: $black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 0px;
  right: 20px;
  margin-top: 0;
  border: 1px solid #efefef;

  .icon-hamburger {
    position: absolute;
    left: 10px;
    top: 18px;
  }

  span {
    display: inline-block;
    margin-top: 3px;
  }

  &:hover,
  &:focus {
    background-color: $accentHover;
    box-shadow: none;
    color: $white;

    .icon-hamburger {
      background-color: $white;
      border-color: $white;

      &:before,
      &:after {
        border-color: $white;
      }
    }
  }

  &:active {
    transform: none;
  }

  @media #{$medium} {
    margin-top: 10px;
  }

  @media #{$nav} {
    display: none;
  }
}

.main-nav__subnav {
  overflow: hidden;
  z-index: 999;
  transition: $transitionDefault;

  @media #{$nav} {
    background-color: $white;
    max-height: 0;
    padding-left: 0;
    border: 1px solid rgba($grayLight, 0);
    margin-bottom: 0;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    display: block;

    &:hover {
      max-height: $subnavMaxHeight;
      border: 1px solid rgba($grayLight, 0.25);
      padding-top: 10px;
    }
  }

  a:not(.button) {
    text-decoration: none;
    padding: 10px;
    display: block;
    margin-bottom: 0;
    transition: $transitionDefault;

    @media #{$nav} {
      padding: 10px 20px;

      &:hover,
      &:focus,
      &:active {
        background-color: lighten($accent, 40%);
      }
    }
  }

  ul {
    margin-bottom: 10px;
    margin-left: 0;

    @media #{$nav} {
      padding-left: 0;
      margin-bottom: 0;
    }
  }
}

.subnav__item {
  margin-bottom: 0;

  @media #{$nav} {
    &:first-child {
      .subnav__label {
        padding-top: 20px;
      }
    }
  }

  a:not(.button) {
    letter-spacing: 0.012em;
    @include fs(12);
    @include lh(14);
    color: $black;
    transition: $transitionDefault;

    @media #{$nav} {
      &:hover,
      &:focus,
      &:active {
        background-color: lighten($accent, 40%);
      }
    }
  }

  ul {
    transition: $transitionDefault;
  }
}

.subnav__label {
  @include fs(10);
  font-style: italic;

  @media #{$nav} {
    padding: 10px 20px;
    font-style: normal;
  }
}

.has-subnav {
  > a {
    position: relative;
    display: inline-block;

    &:before {
      @extend .icon;
      display: none;

      @media #{$nav} {
        content: "\e914";
        float: right;
        padding-left: 5px;
        transform: rotate(0deg);
        display: inline-block;
        margin-top: 2px;
        @include fs(12);
        color: inherit;
      }
    }
  }
}

.main-nav__wrap {
  display: none;
  margin: 0 0 15px;
  width: 100%;
  margin-top: 20px;
  background-color: lighten($accent, 45%);
  border-top: 1px solid $grayLight;
  @include clearfix;

  @media #{$maxNav} {
    position: fixed;
    overflow: scroll;
    top: 0;
    left: 0;
    margin: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    background-repeat: repeat;
    z-index: 99;
    padding: 20px;
  }

  .button--skew {
    @media #{$maxNav} {
      padding: 10px 30px;
      margin-bottom: 20px;

      &:before {
        content: none;
      }
    }
  }

  @media #{$nav} {
    border-top: none;
    display: block;
    width: auto;
    background-color: transparent;
    margin: 0 0 0 -20px;
  }
}

.main-nav__wrap__social {
  margin-bottom: 12px;
  border-bottom: 1px solid $grayLight;
  width: 100%;
  display: block;
  padding: 15px 0;

  li {
    display: inline-block;
    float: none;
    margin-left: 5px;
    vertical-align: middle;

    @media #{$nav} {
      margin-left: 0;
    }
  }

  @media #{$nav} {
    text-align: right;
    border-bottom: none;
    padding: 0;
  }
}

.main-nav__wrap__main {
  margin-bottom: 0;

  @media #{$nav} {
    display: flex;
    align-items: center;
  }
}

.language.language {
  position: relative;
  display: block;
  z-index: 1111;
  margin-top: 20px;

  @media #{$nav} {
    display: inline-block;
    margin-left: 30px !important;
    margin-top: 0;

    &:hover,
    &:focus {
      .language__wrap {
        max-height: $subnavMaxHeight;
        border: 1px solid rgba($grayLight, 0.25);
        padding-top: 10px;
      }
    }
  }
}

.language__trigger {
  position: relative;
  padding: 6px 0;
  background-color: transparent;
  color: $darkBlue;
  margin: 0;
  @include fs(12);
  float: none;
  display: inline-block;

  &:before {
    @extend .icon;
    content: "\e914";
    float: right;
    padding-left: 5px;
    display: none;
    @include fs(12);
    color: inherit;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    color: $accent;
    box-shadow: none;
  }

  @media #{$nav} {
    &:before {
      display: inline-block;
    }

    &:hover,
    &:focus {
      + .language__wrap {
        max-height: $subnavMaxHeight;
        border: 1px solid rgba($grayLight, 0.25);
        padding-top: 10px;
      }
    }
  }
}

.language__wrap {
  z-index: 999;
  list-style: none;
  background-color: $white;
  border: 1px solid rgba($grayLight, 0);
  padding-left: 0;
  margin-bottom: 0;
  display: inline-block;
  transition: $transitionDefault;

  .open & {
    border: 1px solid rgba($grayLight, 0.25);
  }

  li {
    display: inline-block;

    a {
      display: inline-block;
      padding: 10px 5px !important;
      text-decoration: none;
    }
  }

  @media #{$nav} {
    position: absolute;
    top: 100%;
    right: 0;
    max-height: 0;
    display: block;
    overflow: hidden;
    padding-bottom: 10px;

    li {
      display: block;

      a {
        display: block;
        padding: 10px 20px !important;
      }
    }
  }
}

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap;

  &:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    @include fs(20);
  }
}

.visual-bg {
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
}

/* =============================================================================
   MAIN
   ========================================================================== */

.main {
  padding: 20px 0;
  padding-top: 0;

  @media #{$medium} {
    padding: 0px 0 $footerHeightMedium + 40px;
  }

  @media #{$large} {
    padding-bottom: $footerHeightLarge + 40px;
  }
}

/* =============================================================================
   FOOTER
   ========================================================================== */

.page-footer {
  position: relative;
  background-color: $darkBlue;
  overflow: hidden;
  @include clearfix;
  font-weight: 600;

  &:before {
    content: none;
    position: absolute;
    height: 600px;
    width: 20%;
    background-color: $white;
    left: 0;
    top: 0;
    transform: rotate(25deg) translate(-95%, -10%);
  }

  a {
    @include fs(16);
    @include lh(26);
  }

  img {
    margin-bottom: 20px;
  }

  .grid {
    margin-bottom: 0;
  }

  .button-social {
    &:hover,
    &:focus,
    &:active {
      i {
        color: rgba($white, 0.8);
      }
    }

    i {
      color: $white;
    }
  }

  @media #{$medium} {
    height: $footerHeightMedium;
    margin: -$footerHeightMedium 0 0;
    z-index: 5;

    &:before {
      content: "";
    }
  }

  @media #{$large} {
    height: $footerHeightLarge;
    margin: -$footerHeightLarge 0 0;
    z-index: 5;

    img {
      margin-top: -5px;
    }
  }

  @media #{$larger} {
    a {
      @include fs(18);
    }

    img {
      margin-top: -10px;
    }

    .grid {
      margin-left: -40px;

      > .grid__item {
        padding-left: 40px;
      }
    }
  }
}

.footer-navigation {
  margin-bottom: 0;
  margin-left: 0;
  list-style: none;

  li {
    display: inline-block;
    margin-right: 10px;
    font-weight: 300;

    &:first-child {
      display: block;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    @include fs(16);
    @include lh(16);
  }
}

.footer-translations {
  p {
    font-size: 10px;
    font-weight: 100;
  }
}

.social-buttons--footer {
  @media #{$large} {
    text-align: center;
  }
}

.page-footer__content {
  padding: 20px;
  @include clearfix;
  color: $white;
  @include fs(16);

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  a {
    color: $white;
    @include optimize-font;
    margin-bottom: 10px;

    &:hover,
    &:focus,
    &:active {
      color: $white;
    }
  }

  @media #{$medium} {
    padding-top: 125px;
    margin-left: 10%;

    a {
      display: inline-block;
      margin-bottom: 0;
    }
  }

  @media #{$larger} {
    @include fs(18);
    margin-left: 15%;
  }
}

.newsletter-form {
  position: relative;
  margin-bottom: 20px;

  ::placeholder {
    color: $white;
    font-style: normal;
  }

  input[type="email"] {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid rgba($white, 0.5);
    height: 40px;
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 5px;
    margin-top: 5px;
    background-color: $white;
    padding: 5px;
    color: $accent;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    i {
      @include fs(10);
      position: relative;
      left: -2px;
      top: 1px;
    }
  }
}

.social-buttons {
  list-style: none;
  margin-left: 0;
  li {
    display: inline-block;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.footer__heading {
  text-transform: uppercase;
  @include optimize-font;

  font-weight: 600;
}

.page-footer__bottom {
  clear: both;

  ul {
    list-style: none;
    margin-left: 0;
    margin-bottom: 20px;

    @media #{$medium} {
      margin-bottom: 0;
    }

    li {
      display: inline-block;
      color: $white;
      @include fs(16);

      &:after {
        content: "";
        display: inline-block;
        background-color: $white;
        border-radius: 50%;
        width: 5px;
        height: 5px;
        margin-top: -3px;
        margin-left: 5px;
        margin-right: 5px;
        overflow: hidden;
        top: -3px;
        position: relative;
      }

      &:last-child {
        &:after {
          content: none;
        }
      }

      a {
        color: $white;
        display: inline-block;
      }
    }
  }

  @media #{$medium} {
    ul {
      margin-bottom: 0;
    }
  }
}

a.esign {
  width: 80px;
  height: 20px;
  background-image: url("../images/logo-esign-white.svg");
  background-size: 77px 20px;
  background-repeat: no-repeat;
  background-position: top left;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  text-indent: -9999px;
  opacity: 0.6;
  transition-duration: 0.5s;
  display: block;
  outline: 0;
  z-index: 9999;
  margin-bottom: 0;

  &:hover,
  &:focus {
    opacity: 1;
  }

  @media #{$medium} {
    width: 16px;
    position: absolute;
    right: 45px;
    bottom: 0;
    margin-bottom: 20px;

    &:hover,
    &:focus {
      opacity: 1;
      width: 80px;
    }
  }
}

/* =============================================================================
   COMPONENTS
   ========================================================================== */

// Rte
.large {
  @include fs(20);
}

.center-block {
  @include clearfix;
  text-align: center;
  margin: 20px 0;

  .button {
    float: none;
    display: inline-block;
  }
}

// Article
section {
  position: relative;
}

article {
  @include clearfix;
  margin: 0 0 20px;

  a {
    img {
      display: block;
      @include opacity(100);
    }

    &:hover,
    &:focus {
      img {
        @include opacity(80);
      }
    }
  }
}

.article-detail {
}

.article-detail__image {
  margin-bottom: 1.5em;
}

// Note
.note {
  @include clearfix;
  margin: 0 0 20px;
  padding: 15px 20px 0;
  background: #efefef;

  ul,
  p {
    margin-bottom: 1em;
  }
}

.note--success {
  color: $success;
  background-color: $successBg;
  padding-bottom: 15px;
}

.note--error {
  color: $error;
  background-color: $errorBg;
}

// Slideshow
.cycle-slideshow {
  width: 100%;
  position: relative;
}

.cycle-pager {
  width: 100%;
  text-align: center;

  span {
    display: inline-block;
    margin: 0 3px;
    font-size: 36px;
    height: 20px;
    width: 20px;
    line-height: 14px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    overflow: hidden;
    text-indent: -500px;
    transition: $transitionDefault;

    &:hover,
    &:focus {
      transition: none;
    }
  }

  span:hover,
  span:focus,
  .cycle-pager-active {
    text-indent: 0;
  }
}

// Map
.map {
  width: 100%;
  height: 350px;
  background: #efefef;
  margin: 0 0 40px;

  @media #{$medium} {
    height: 550px;
  }
}

// Social
.social {
  @include clearfix;
  list-style: none;
  margin: 0 0 2em -5px;
}

.social__item {
  padding: 0 0 5px 5px;
  float: left;
}

.social__link {
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: block;
  border-radius: 40px;
  background-color: $accent;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
}

.social__link--facebook {
  background-color: #3c5b9b;
  &:hover,
  &:focus {
    background-color: darken(#3c5b9b, 8%);
  }
}

.social__link--twitter {
  background-color: #2daae1;
  &:hover,
  &:focus {
    background-color: darken(#2daae1, 8%);
  }
}

.social__link--pinterest {
  background-color: #cb2027;
  &:hover,
  &:focus {
    background-color: darken(#cb2027, 8%);
  }
}

.social__link--linkedin {
  background-color: #0173b2;
  &:hover,
  &:focus {
    background-color: darken(#0173b2, 8%);
  }
}

.section-wrap {
  /*  position: relative;*/
  // overflow-y: visible;
  // overflow-x: hidden;

  padding-top: 25px;
  padding-bottom: 25px;

  @media #{$medium} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media #{$large} {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.section-wrap-overflow {
  overflow: hidden;
}

.section-wrap--hexagon {
  @media #{$medium} {
    margin-top: 80px;

    margin-bottom: 130px;
  }
}

.section-wrap--grey {
  background-color: #0000000c;
}

.section-wrap--first {
  padding-top: 0;
}

.section-wrap--last {
  padding-bottom: 0;
}

.element-with-shadow {
  box-shadow: 5px 5px 25px rgba($grayLight, 0.5);
  margin-bottom: 20px;

  img {
    width: 100%;
  }
}

.img-wrap,
.img-wrapper {
  &.medium-w {
    text-align: center;

    img {
      max-width: 500px;
    }
  }
  img {
    width: 100%;
  }
}

.open-video {
  position: relative;
  @extend .icon;
  @extend .icon-play;

  padding: 0;

  &:hover,
  &:focus,
  &:active {
    &:after {
      transform: translate(-50%, -50%) scale(1.1);
      background-color: $accent;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: $darkBlue;
    width: 65px;
    padding-left: 0;
    height: 65px;
    transition: $transitionDefault;
  }

  &:before {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include fs(30);
    border-radius: 50%;
    width: 65px;
    padding-left: 0;
    height: 65px;
    color: $white;
    @include lh(65);
    transition: $transitionDefault;
    margin-left: -3px;
    z-index: 11;
  }
}

.heading-with-button {
  @media #{$medium} {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}

.heading-with-button__button {
}

.heading-with-button__heading {
  @media #{$medium} {
    margin-right: 40px;
  }
  @media #{$larger} {
    margin-right: 80px;
  }
}

.hexagon {
  position: relative;
  //z-index: 99;
  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    width: 90%;
    height: 0;
    padding-bottom: 90%;
    margin-left: -305px;
    margin-top: 10px;
    transform: translateY(-50%);

    //background-color: $pink;
    display: block;
    z-index: -1;
    //background-image: url('../images/hexagon.svg');
    background-image: url("../images/hexagon-border-10.svg");
    opacity: 0.5;
    // z-index: 1;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media #{$medium} {
      content: "";
    }

    @media #{$large} {
      margin-left: -150px;
    }
    @media #{$larger} {
      margin-left: -200px;
    }
    @media #{$huge} {
      margin-left: -220px;
      margin-top: -15px;
    }
  }
}

.hexagon--medium {
  &:before {
    width: 75%;
    padding-bottom: 75%;
    //margin-top: 20px;

    //padding-bottom: 70%;

    // margin-top: -20px;
  }
}

.hexagon--right {
  &.hexagon--medium {
    &:before {
      // margin-right: -125px;
      // right: -20%;

      @media #{$larger} {
        // margin-right: -200px;
      }
    }
  }
  &:before {
    left: auto;
    right: 0;
    margin-left: 0;
    //margin-right: -50px;
    right: -15%;
  }
}

.hexagon--top {
  &:before {
    top: 0;
    //margin-top: -100px;
    transform: translateY(-30%);
  }
}

.hexagon--static-w {
  @media #{$medium} {
    &:before {
      width: 400px;
      height: 400px;
      margin-top: -75px;
    }
  }
}

.hexagon--top-half {
  &:before {
    top: 0;
    margin-top: -100px;
    transform: translateY(0);
  }
}

.header {
  width: 100%;

  background-size: cover;
  background-position: center;
  position: relative;
  //margin-bottom: 40px;

  @media #{$medium} {
    height: 450px;
    //margin-bottom: 100px;
  }
  @media #{$large} {
    height: 500px;
    // margin-bottom: 100px;
  }
  @media #{$larger} {
    height: 600px;
    // margin-bottom: 100px;
  }
  @media #{$huge} {
    height: 750px; //margin-bottom: 130px;
  }

  img {
    @media #{$medium} {
      visibility: hidden;
    }
  }

  .button {
    // margin-right: 10px;
  }
}

.header-wrap {
  width: 100%;
  @include clearfix;
}

.header-wrap--right {
  @media #{$medium} {
    padding-left: 40px;
  }
  @media #{$larger} {
    padding-left: 80px;
  }

  .header {
    float: right;
  }
}

.header-wrap--left {
  @media #{$medium} {
    padding-right: 40px;
  }
  @media #{$larger} {
    padding-right: 80px;
  }

  .header {
    float: left;
  }
}

.push-left {
  padding-left: 40px;

  @media #{$medium} {
    padding-left: 40px;
  }
  @media #{$larger} {
    padding-left: 80px;
  }
}

.header__video {
  height: 300px;
  overflow: hidden;
  display: none;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media #{$small} {
    height: 0;
    padding-bottom: 65%;
  }

  @media #{$medium} {
    display: block;
    height: auto;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.header__buttons {
  display: inline-flex;
  margin-right: 20px;
}

.header__video-button {
  height: 65px;
  width: 65px;
  z-index: 1;
  transform: scale(0.738461538) translateY(9px);
  transform-origin: top left;
  display: inline-flex;
  align-self: flex-end;

  @media #{$small} {
    margin-top: -1px;
    display: inline-block;
  }
}

.header__content {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 20px;
  width: 100%;

  h1 {
    @extend .heading-shadow;
    color: $white;
    @media #{$mobile} {
      font-size: 21px;
    }
  }

  @media #{$medium} {
    margin-bottom: 40px;
  }

  .button {
    display: inline-block;
    float: none;

    @media #{$mobile} {
      font-size: 12px;
      padding: 10px 15px;
    }
  }
}

.after-header-block {
  margin-top: 0px;
  padding: 20px;
  padding-bottom: 0;

  .h1 {
    margin-top: 10px;
  }

  @media #{$medium} {
    padding: 0;
    position: relative;
    top: -160px;
    margin-top: 20px;
    margin-bottom: -160px;
    //transform: translateY(-50%);
    // margin-top: -160px;
    // margin-bottom: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
}

.grid--bottom-aligned {
}

.blue-block {
  padding: 20px;
  margin-bottom: 0px;
  width: auto;
  background-color: $accent;
  color: $white;
  z-index: 99;
  margin-bottom: 20px;
  font-weight: 200;

  @media #{$medium} {
    margin-bottom: 0;
  }

  //max-width: 70%;

  p {
    margin-bottom: 0;
    @media #{$medium} {
      @include fs(20);
      @include lh(25);
    }
    @media #{$large} {
      @include fs(25);
      @include lh(38);
    }

    @media #{$larger} {
      @include fs(36);
      @include lh(48);
    }
  }

  @media #{$medium} {
    padding: 40px;
  }

  @media #{$larger} {
    padding: 80px;
    max-width: 710px;
  }
}

.cta-block-link-wrap {
}

.cta-block {
  width: 100%;
  height: 200px;
  overflow: hidden;

  position: relative;
  margin-bottom: 20px;

  @media #{$medium} {
    height: 300px;
    margin-bottom: 0;
  }
  @media #{$large} {
    height: 400px;
  }
  @media #{$huge} {
    height: 500px;
  }

  .heading-with-label__heading,
  .heading-with-label__label {
    color: $white;
  }

  a {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    &:hover,
    &:focus,
    &:active {
      .cta-block__visual {
        transform: scale(1.1);

        &:after {
          opacity: 0.3;
        }
      }
    }
  }
}

.cta-block__visual {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  transition: $transitionDefault;
  position: absolute;
  top: 0;
  left: 0;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $darkBlue;
    opacity: 0;
    transition: $transitionDefault;
  }
}

.cta-block--right-bg {
  @media #{$mobile} {
    background-position: 80%;
  }
}

.cta-block__content {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 20px;
  color: $white;
  padding: 20px;

  a,
  span {
    color: $white;
  }
}

/* =============================================================================
   PAGES
   ========================================================================== */

/* =================================================
   HOME
   ================================================= */

.news-overview__article {
  margin-bottom: 40px;

  .block-link {
    text-decoration: none;
    display: block;

    &:hover,
    &:focus,
    &:active {
      .news-overview__article__title {
        color: $darkBlue;
      }

      .arrowed-link {
      }

      .news-overview__article__image {
        transform: scale(1.1);

        &:after {
          opacity: 0.25;
          //  transform: scale(1);
        }
      }
    }
  }

  @media #{$medium} {
    margin-bottom: 80px;
  }
}

.home {
  .news-overview__article {
    margin-bottom: 20px;
    @media #{$medium} {
      margin-bottom: 0;
    }
  }
}

.news-overview__article__image-wrap {
  overflow: hidden;
  width: 100%;
  padding-bottom: 72.2891566%;
  height: 0;
  display: block;
  margin-bottom: 10px;
}

.news-overview__article__image {
  width: 100%;
  padding-bottom: 72.2891566%;
  text-transform: uppercase;
  display: block;

  transition: $transitionDefault;
  background-size: cover;
  background-position: center;
  position: relative;

  &:after {
    content: "";
    background-color: $darkBlue;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    //transform: scale(0);
    transition: $transitionDefault;
  }

  img {
    @extend .vh;
  }
}

.news-overview__article__title {
  text-transform: none;
  @include fs(24);
  color: $accent;
}

.news-overview__article__description {
  @include fs(16);
}

.news-overview__article__date {
  text-transform: uppercase;
  margin-bottom: 15px;
  color: $grayDark;
  @include fs(13);
  font-weight: 600;
  @include optimize-font;
  letter-spacing: 0.015em;
}

.pagination {
  .pagination-pager,
  p > a {
    text-decoration: none;
    color: $darkBlue;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: 600;
    //@include fs(13);

    &:hover,
    &:focus,
    &:active,
    &.active {
      text-decoration: underline;
    }
  }

  .arrow {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;

    a {
      color: $darkBlue;

      span {
        transition: $transitionDefault;
      }

      &:hover,
      &:focus,
      &:active {
        color: $accent;

        span {
          color: $accent;
        }
      }
    }

    i {
      &:before {
        @include fs(12);
        display: inline-block;
      }
    }

    &.prev {
      i {
        &:before {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.material-block--txt-img {
  @media #{$medium} {
    margin-bottom: 120px;
  }
}

.partner-wrap {
  width: 100%;
  position: relative;
  border: 1px solid rgba($grayLight, 0.25);
  margin-bottom: 20px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    @include object-fit(contain, center);
  }

  &:hover,
  &:focus,
  &:active {
    .partner__overlay {
      top: 0;
    }
  }

  @media #{$medium} {
    min-height: 200px;
  }
}

.unleash-body-wrap {
  .partner-wrap {
    .partner__overlay {
      top: 0;
      display: block;
    }
  }
}

.partner {
  width: 100%;
  height: 100%;
  padding: 0px;
  transition: $transitionDefault;
  position: relative;
  overflow: hidden;
  display: flex;

  @media #{$medium} {
    padding: 20px;
  }

  @media #{$large} {
    padding: 40px;
  }

  @media #{$larger} {
    padding: 40px 60px;
  }
}

.partner__visual {
  height: 100%;
  max-height: 150px;
  width: 100%;
  object-fit: contain;
  object-position: center;
  padding: 20px;

  @media #{$medium} {
    width: 100%;
    //height: 100%;
    margin-left: auto;
    margin-right: auto;

    height: auto;
  }

  @media #{$larger} {
    padding: 10px;
  }
}

.partner__overlay {
  //backface-visibility: hidden;
  z-index: 99;
  // z-index: 1;
  background-color: $accentHover;
  //text-align: center;
  padding: 20px;
  color: $white;
  transition: $transitionDefault;

  display: none;
  //opacity: 0;
  //transition-duration: 0.4s;
  //transform: rotateY(180deg);
  @media #{$medium} {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 100%;
  }

  @media #{$larger} {
    //height: 150px;
  }
  p {
    //margin-bottom: 5px;
    @include fs(12);
    @include lh(20);
    font-weight: 600;
    @include optimize-font;
    letter-spacing: 0.05em;

    @media #{$larger} {
      @include fs(14);
    }
  }

  i {
    @include fs(11);
  }

  a {
    display: block;
    color: $white;
    text-decoration: none;
  }
}

/* =================================================
   Patient
   ================================================= */

.flow-grid {
  .skewed-items-grid__item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    @media #{$between_medium_and_large} {
      &:nth-child(even) {
        // background-color: red;
        .flow-item__title {
          //margin-left: 0;

          width: calc(100% - 5px);
          &:after {
            content: none;
          }
        }
      }
      &:nth-child(odd) {
        .flow-item__title {
          margin-left: 0;
          width: calc(100% - 20px);
          &:before {
            content: none;
          }
        }
      }
    }

    @media #{$large} {
      &:first-child,
      &:nth-child(4n + 1) {
        .flow-item__title {
          margin-left: 0;
          width: calc(100% - 20px);
          &:before {
            content: none;
          }
        }
      }

      &:nth-child(4n) {
        .flow-item__title {
          //margin-left: 0;
          width: calc(100% - 5px);
          &:after {
            content: none;
          }
        }
      }
    }
  }
}

.flow-item {
  text-decoration: none;
  display: block;

  p {
    color: $grayDark;
    /*@include fs(14);
    @include lh(28);*/
  }

  &:hover,
  &:focus,
  &:active {
    .flow-item__title,
    .flow-item__title:before,
    .flow-item__title:after {
      background-color: $accent;
    }
  }
}

.flow-item__visual {
  width: 100%;
  height: 0;
  padding-bottom: 73.66%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  margin-bottom: 0;
}

.flow-item__title,
.psi-item__title {
  background-color: $darkBlue;
  position: relative;
  padding: 20px 10px;
  display: inline-block;
  width: 100%;

  color: $white;
  margin-top: -1px;
  transition: $transitionDefault;
  margin-bottom: 20px;
  text-align: center;
  @include fs(12);
  @include lh(18);

  font-weight: 600;
  //@include display-font;
  letter-spacing: 0.15em;

  @media #{$medium} {
    width: calc(100% - 25px);
    margin-left: 5px;
    margin-bottom: 40px;
  }

  @media #{$larger} {
    @include fs(14);
  }

  &:before,
  &:after {
    width: 30px;
    height: 100%;
    background-color: $darkBlue;
    position: absolute;
    top: 0;
    transform: skew(-19deg);
    z-index: -1;
    transition: $transitionDefault;
    @media #{$medium} {
      content: "";
    }
  }

  &:before {
    left: 0;
    margin-left: -15px;
  }

  &:after {
    right: 0;
    margin-right: -15px;
  }
}

/* =================================================
   Concept solution (flow)
   ================================================= */

.concept-solution-heading {
  background-color: $accent;
  padding: 30px 0;
  width: 100%;

  .heading-with-label__heading {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
    margin-top: 20px;
    color: $white;

    @media #{$medium} {
      margin-top: 50px;
    }
  }

  @include clearfix;
  margin-bottom: 20px;

  @media #{$medium} {
    margin-bottom: 60px;
  }

  @media #{$medium} {
    padding: 50px 0;
  }
  @media #{$large} {
    padding: 80px 0;
  }

  .heading-with-label__label,
  .heading-with-label__heading {
    color: $white;
  }
}

.medium--pull-top {
  @media #{$medium} {
    margin-top: -200px;
    float: left;
  }

  @media #{$large} {
    margin-top: -275px;
  }
}

.proces-list {
  counter-reset: procesCounter;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 40px;

  @media #{$medium} {
    margin-top: 60px;
    margin-bottom: 80px;
  }
}

.proces-listitem {
  counter-increment: procesCounter;
  color: $grayDark;

  background-image: linear-gradient(
    to right,
    $darkBlue 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 7px 1px;
  background-repeat: repeat-x;
  margin-bottom: 30px;

  &:last-child {
    background: none;
    margin-bottom: 0;
  }

  @media #{$large} {
    margin-left: -20px;
    @include clearfix;

    .proces-listitem__visual,
    .proces-listitem__body {
      float: left;
      padding-left: 20px;
    }
    .proces-listitem__visual {
      width: 33.33%;
    }
    .proces-listitem__body {
      width: 66.66%;
    }
  }
}

.proces-listitem__visual {
  @include fs(32);
  text-align: center;
  margin-bottom: 20px;

  @media #{$medium} {
    @include fs(35);
  }

  @media #{$large} {
    text-align: left;
    margin-bottom: 0;
  }

  &:before {
    content: counter(procesCounter) ".";
    display: inline-block;
    margin-right: 50px;

    @media #{$larger} {
      // margin-bottom: 50px;
    }
  }
  i {
    color: $darkBlue;
  }
}

.proces-images {
  .grid {
    margin-bottom: 0;
  }
}

.proces-image {
  width: 100%;
  display: block;
  overflow: hidden;
  margin-bottom: 20px;

  &:hover,
  &:focus,
  &:active {
    .proces-image__image {
      transform: scale(1.1);
    }
  }
}

.proces-image__image {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  display: block;
  transition: $transitionDefault;

  img {
    visibility: hidden;
  }
}

.choose-us-list {
  li {
    color: $grayDark;
    font-weight: 600;
    @include lh(32);
  }
}

/* =================================================
   Surgeon
   ================================================= */

.psi-grid {
  .psi-item__title {
    margin-bottom: 0;
  }
  .skewed-items-grid__item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }

    @media #{$between_medium_and_large} {
      &:nth-child(odd) {
        .psi-item__title {
          &:before {
            content: none;
          }
        }
      }
      &:nth-child(even) {
        .psi-item__title {
          &:after {
            content: none;
          }
        }
      }
    }

    /*
        @media #{$between_medium_and_large} {
          &:nth-child(2n) {

            // background-color: red;
            .psi-item__title {
              //margin-left: 0;

              width: calc(100% - 5px);
              &:after {
                content: none;

              }
            }
          }

          &:nth-child(2n +1) {
            .psi-item__title {

              margin-left: 0;
              width: calc(100% - 20px);
              &:before {
                content: none;

              }
            }
          }

          &:nth-child(3) {
            margin-right: -25px;

            .psi-item__title {
              width: calc(100% - 40px);
            }
          }

          &:nth-child(5) {

            .psi-item__title {
              width: calc(100% - -20px);
            }
          }

          &:nth-child(6) {
            margin-right: -45px;

            .psi-item__title {
              width: calc(100% - 60px);
            }
          }

          &:nth-child(8) {

            .psi-item__title {
              width: calc(100% - -40px);
            }
          }

        }*/

    @media #{$large} {
      &:nth-child(3n) {
        // background-color: red;
        .psi-item__title {
          //margin-left: 0;

          width: calc(100% - 5px);
          &:after {
            content: none;
          }
        }
      }

      &:nth-child(3n + 1) {
        .psi-item__title {
          margin-left: 0;
          width: calc(100% - 20px);
          &:before {
            content: none;
          }
        }
      }

      &:nth-child(4) {
        margin-right: -25px;

        .psi-item__title {
          width: calc(100% - 40px);
        }
      }

      &:nth-child(6) {
        .psi-item__title {
          width: calc(100% - -20px);
        }
      }

      &:nth-child(7) {
        margin-right: -45px;

        .psi-item__title {
          width: calc(100% - 60px);
        }
      }

      &:nth-child(9) {
        .psi-item__title {
          width: calc(100% - -40px);
        }
      }
    }
  }
}

.psi-item {
  text-decoration: none;
  display: block;

  &:hover,
  &:focus,
  &:active {
    .psi-item__title,
    .psi-item__title:before,
    .psi-item__title:after {
      background-color: $accent;
    }
  }
}

.tech-specs-overview__article {
  margin-bottom: 30px;
  @media #{$medium} {
    margin-bottom: 0;
  }
  &.block-link {
    &:hover,
    &:focus,
    &:active {
      .tech-specs-overview__item__image {
        transform: scale(1.1);

        &:before {
          opacity: 0.3;
        }
      }
    }
  }
}

.tech-specs-overview__item {
  margin-bottom: 20px;
  @media #{$medium} {
    margin-bottom: 0;
  }

  &:hover,
  &:focus,
  &:active {
  }
}

.tech-specs-overview__item__image-wrap {
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 72.2891566%;
  position: relative;
  margin-bottom: 20px;
}

.tech-specs-overview__item__image {
  text-transform: uppercase;
  display: block;

  width: 100%;
  height: 0;
  padding-bottom: 72.2891566%;
  background-size: cover;
  background-position: center;
  transition: $transitionDefault;
  @media #{$medium} {
    margin-bottom: 40px;
  }

  &:before {
    content: "";
    background-color: $darkBlue;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: $transitionDefault;
  }
}

.tech-specs-overview__item__title {
  text-transform: none;
  @include fs(24);
  @include lh(30);
  color: $darkBlue;
  margin-bottom: 20px;

  @media #{$medium} {
    margin-bottom: 30px;
  }
}

.tech-specs-overview__item__description {
  @include fs(16);
  margin-bottom: 12px;
}

.grid--treatments {
  > .grid__item {
    margin-bottom: 30px;

    .img-wrapper {
      margin-bottom: 20px;

      img {
        @include object-fit(contain, center);
        height: 300px;
      }
    }

    h2 {
      margin-bottom: 10px;
    }

    @media #{$medium} {
      //margin-bottom: 60px;
      margin-bottom: 80px;

      &:nth-child(3n + 1) {
        //background-color: red;
        clear: both;
      }
    }
  }
}

/* =================================================
   Concept solution (surgeon)
   ================================================= */

.spec-buttons {
  .button {
    background-color: $darkBlue;
    position: relative;
    clear: none;
    margin-right: 20px;
    float: none;
    padding: 10px;
    display: inline-block;
    //width: calc(100% - 25px);
    margin-left: 5px;
    color: $white;
    margin-top: -1px;
    transition: $transitionDefault;
    margin-bottom: 40px;
    text-align: center;
    @include fs(12);
    font-weight: 600;
    //@include display-font;
    letter-spacing: 0.15em;

    &:hover,
    &:focus,
    &:active {
      background-color: $accent;
      &:after,
      &:before {
        background-color: $accent;
      }
    }

    @media #{$larger} {
      padding: 15px;
      @include fs(14);
    }

    &:first-child {
      &:before {
        content: none;
      }
    }
    &:last-child {
      &:after {
        content: none;
      }
    }

    &:before,
    &:after {
      content: "";
      width: 20px;
      height: 100%;
      background-color: $darkBlue;
      position: absolute;
      top: 0;
      transform: skew(-20deg);
      transition: $transitionDefault;
    }

    &:before {
      left: 0;
      margin-left: -10px;
    }

    &:after {
      right: 0;
      margin-right: -10px;
    }
  }
}

/* =================================================
   Tech
   ================================================= */

.tech-nav-grid {
  @extend .psi-grid;
  margin-bottom: 40px;
}

.tech-nav-item {
  @extend .psi-item;
  text-transform: uppercase;
}

.tech-nav-item__title {
  @extend .psi-item__title;
  padding: 20px;
  @include lh(10);
}

/* =================================================
   Team
   ================================================= */

.team-grid {
  > .grid__item {
    @media #{$medium} {
      &:nth-child(2n + 1) {
        clear: both;
      }
    }
    @media #{$large} {
      &:nth-child(2n + 1) {
        clear: none;
      }

      &:nth-child(3n + 1) {
        clear: both;
      }
    }
  }
}

.team-member {
  width: 100%;
  margin-bottom: 40px;
}

.team-member__visual {
  height: 300px;
  background-size: cover;
  background-position: center;
  width: 100%;
  margin-bottom: 20px;
}

.team-member__name {
  color: $darkBlue;
  @include default-font();
  @include fs(24);
}

.team-member__visual--placeholder {
  background-color: #f8f8f8;
  position: relative;

  i {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include fs(50);
    color: #b9b9b9;

    @media #{$small} {
      @include fs(80);
    }
  }
}

.team-expertises {
  list-style: none;
  margin-left: 0;

  li {
    display: block;

    span {
      display: inline-block;
      vertical-align: top;
    }
  }
}

.expertise__name {
  @include default-font();
  @include fs(24);
  color: $darkBlue;
  margin-right: 40px;
}

.expertise__person {
  color: $grayMiddle;
  padding-top: 10px;

  p {
    margin-bottom: 10px;
  }
}

/* =================================================
   News
   ================================================= */

.grid--news {
  @media #{$medium} {
    > .grid__item {
      &:nth-child(3n + 1) {
        clear: both;
      }
    }
  }
}

/* =================================================
   News detail
   ================================================= */

.news-detail__navigation {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 0;
  padding: 0 40px;
  margin-bottom: 20px;
  text-align: center;

  @media #{$medium} {
    text-align: left;
    justify-content: space-between;
    margin-bottom: 80px;
  }

  li {
    flex-shrink: 0;
    width: 100%;
    float: none;
    margin-bottom: 20px;
    display: block;

    &:first-child {
      margin-left: -0px;
      // background-color: red;

      order: 2;
      @media #{$medium} {
        order: initial;
      }
    }

    &:last-child {
      margin-left: -0px;
    }

    @media #{$medium} {
      width: auto;
    }
  }
}

.news-detail__navigation__overview-link {
  @extend .arrowed-link;
  //order: 1;
  &:after {
    content: none;
  }
}

/* =================================================
   Contact
   ================================================= */
.static-map-wrapper {
  margin-bottom: 30px;
}

.contact-info {
  margin-left: auto;
  margin-right: auto;
  display: inline-block;

  ul {
    text-align: left;
    display: inline-block;
    margin-right: 40px;
    list-style: none;
    margin-left: 0;

    a {
      color: $darkBlue;
      &:hover,
      &:focus,
      &:active {
        color: $accent;
      }
    }

    &:first-child {
      a {
        text-decoration: none;
      }
    }
  }
}

.directions-link-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  @include clearfix;
  flex-wrap: wrap;
  color: $darkBlue;
  margin-bottom: 20px;
}

.directions-link__visual {
  /*  width: 100%;
    height: 0;*/
  display: inline-block;
  text-align: center;
  //padding-bottom: 25%;
  background-size: cover;
  background-position: center;
  background-position-y: 0;
  //margin-bottom: 20px;
  margin-bottom: 0;
  margin-right: 20px;
  width: 80px;
  height: 80px;
  position: relative;

  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $accent;
    opacity: 0;
    transition: $transitionDefault;
    content: "";
  }

  &:hover,
  &:focus,
  &:active {
    &:after {
      opacity: 0.25;
    }
  }
}

.directions-link__button {
  letter-spacing: 0.15em;
  color: $accent;
  margin-bottom: 0;
  @include smart-underline($white, $accent);
  @include fs(13);
  @include lh(24);
  font-weight: 600;
}

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm;
  }

  html {
    font-size: 10px;
  }

  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }

  .page-footer,
  .main-nav {
    display: none;
  }

  .medium--one-half {
    width: 50%;
  }
  .medium--two-thirds {
    width: 66.66%;
  }
  .medium--one-third {
    width: 33.33%;
  }
  .medium--three-quarters {
    width: 75%;
  }
  .medium--one-quarter {
    width: 25%;
  }
}
