/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */
/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */
/* =============================================================================
   IMPORTS
   ========================================================================== */
/*============================================================================
  RESET

  Adapted version of:
  - Eric Meyer reset
  - Boilerplate Normalize.css
  - Normalize opentype
==============================================================================*/
html {
  overflow-y: scroll;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, body {
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del,
dfn, em, img, q, s, samp, small, strike, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset,
form, label, legend, button, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  line-height: 1;
  font-family: inherit; }

article, aside, details, figcaption, figure, footer, header, hgroup, nav, time, section, main {
  display: block; }

*, *:before, *:after {
  box-sizing: border-box; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none; }

a:focus {
  outline: thin dotted; }

ol, ul {
  list-style: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: ""; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

figure {
  margin: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

abbr {
  text-transform: uppercase; }

time {
  display: block; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline; }

button, input {
  line-height: normal; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

/*============================================================================
  VARIABLES
==============================================================================*/
@media (min-width: 480px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 768px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1024px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1200px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1400px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1680px) {
  .init-mediaqueries {
    margin: 0; } }

/*============================================================================
  MIXINS
==============================================================================*/
.clearfix {
  *zoom: 1; }
  .clearfix:before, .clearfix:after {
    display: table;
    content: " "; }
  .clearfix:after {
    clear: both; }

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr; }

.hidden {
  display: none !important;
  visibility: hidden; }

.vh, .news-overview__article__image img {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.vh.focusable:active, .news-overview__article__image img.focusable:active, .vh.focusable:focus, .news-overview__article__image img.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

/*
==============================================
CSS3 ANIMATION CHEAT SHEET
==============================================

Made by Justin Aguilar

www.justinaguilar.com/animations/

Questions, comments, concerns, love letters:
justin@justinaguilar.com
==============================================
*/
/*
==============================================
slideDown
==============================================
*/
.slideDown {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideDown {
  0% {
    transform: translateY(-100%); }
  50% {
    transform: translateY(8%); }
  65% {
    transform: translateY(-4%); }
  80% {
    transform: translateY(4%); }
  95% {
    transform: translateY(-2%); }
  100% {
    transform: translateY(0%); } }

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%); }
  50% {
    -webkit-transform: translateY(8%); }
  65% {
    -webkit-transform: translateY(-4%); }
  80% {
    -webkit-transform: translateY(4%); }
  95% {
    -webkit-transform: translateY(-2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    transform: translateY(100%); }
  50% {
    transform: translateY(-8%); }
  65% {
    transform: translateY(4%); }
  80% {
    transform: translateY(-4%); }
  95% {
    transform: translateY(2%); }
  100% {
    transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    transform: translateX(150%); }
  50% {
    transform: translateX(-8%); }
  65% {
    transform: translateX(4%); }
  80% {
    transform: translateX(-4%); }
  95% {
    transform: translateX(2%); }
  100% {
    transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

/*
==============================================
slideRight
==============================================
*/
.slideRight {
  animation-name: slideRight;
  -webkit-animation-name: slideRight;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideRight {
  0% {
    transform: translateX(-150%); }
  50% {
    transform: translateX(8%); }
  65% {
    transform: translateX(-4%); }
  80% {
    transform: translateX(4%); }
  95% {
    transform: translateX(-2%); }
  100% {
    transform: translateX(0%); } }

@-webkit-keyframes slideRight {
  0% {
    -webkit-transform: translateX(-150%); }
  50% {
    -webkit-transform: translateX(8%); }
  65% {
    -webkit-transform: translateX(-4%); }
  80% {
    -webkit-transform: translateX(4%); }
  95% {
    -webkit-transform: translateX(-2%); }
  100% {
    -webkit-transform: translateX(0%); } }

/*
==============================================
slideExpandUp
==============================================
*/
.slideExpandUp {
  animation-name: slideExpandUp;
  -webkit-animation-name: slideExpandUp;
  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease -out;
  visibility: visible !important; }

@keyframes slideExpandUp {
  0% {
    transform: translateY(100%) scaleX(0.5); }
  30% {
    transform: translateY(-8%) scaleX(0.5); }
  40% {
    transform: translateY(2%) scaleX(0.5); }
  50% {
    transform: translateY(0%) scaleX(1.1); }
  60% {
    transform: translateY(0%) scaleX(0.9); }
  70% {
    transform: translateY(0%) scaleX(1.05); }
  80% {
    transform: translateY(0%) scaleX(0.95); }
  90% {
    transform: translateY(0%) scaleX(1.02); }
  100% {
    transform: translateY(0%) scaleX(1); } }

@-webkit-keyframes slideExpandUp {
  0% {
    -webkit-transform: translateY(100%) scaleX(0.5); }
  30% {
    -webkit-transform: translateY(-8%) scaleX(0.5); }
  40% {
    -webkit-transform: translateY(2%) scaleX(0.5); }
  50% {
    -webkit-transform: translateY(0%) scaleX(1.1); }
  60% {
    -webkit-transform: translateY(0%) scaleX(0.9); }
  70% {
    -webkit-transform: translateY(0%) scaleX(1.05); }
  80% {
    -webkit-transform: translateY(0%) scaleX(0.95); }
  90% {
    -webkit-transform: translateY(0%) scaleX(1.02); }
  100% {
    -webkit-transform: translateY(0%) scaleX(1); } }

/*
==============================================
expandUp
==============================================
*/
.expandUp {
  animation-name: expandUp;
  -webkit-animation-name: expandUp;
  animation-duration: 0.7s;
  -webkit-animation-duration: 0.7s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes expandUp {
  0% {
    transform: translateY(100%) scale(0.6) scaleY(0.5); }
  60% {
    transform: translateY(-7%) scaleY(1.12); }
  75% {
    transform: translateY(3%); }
  100% {
    transform: translateY(0%) scale(1) scaleY(1); } }

@-webkit-keyframes expandUp {
  0% {
    -webkit-transform: translateY(100%) scale(0.6) scaleY(0.5); }
  60% {
    -webkit-transform: translateY(-7%) scaleY(1.12); }
  75% {
    -webkit-transform: translateY(3%); }
  100% {
    -webkit-transform: translateY(0%) scale(1) scaleY(1); } }

/*
==============================================
fadeIn
==============================================
*/
.fadeIn {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes fadeIn {
  0% {
    transform: scale(0);
    opacity: 0.0; }
  60% {
    transform: scale(1.1); }
  80% {
    transform: scale(0.9);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.0; }
  60% {
    -webkit-transform: scale(1.1); }
  80% {
    -webkit-transform: scale(0.9);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
    opacity: 1; } }

/*
==============================================
expandOpen
==============================================
*/
.expandOpen {
  animation-name: expandOpen;
  -webkit-animation-name: expandOpen;
  animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  visibility: visible !important; }

@keyframes expandOpen {
  0% {
    transform: scale(1.8); }
  50% {
    transform: scale(0.95); }
  80% {
    transform: scale(1.05); }
  90% {
    transform: scale(0.98); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes expandOpen {
  0% {
    -webkit-transform: scale(1.8); }
  50% {
    -webkit-transform: scale(0.95); }
  80% {
    -webkit-transform: scale(1.05); }
  90% {
    -webkit-transform: scale(0.98); }
  100% {
    -webkit-transform: scale(1); } }

/*
==============================================
bigEntrance
==============================================
*/
.bigEntrance {
  animation-name: bigEntrance;
  -webkit-animation-name: bigEntrance;
  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  visibility: visible !important; }

@keyframes bigEntrance {
  0% {
    transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2; }
  30% {
    transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1; }
  45% {
    transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1; }
  60% {
    transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1; }
  75% {
    transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1; }
  90% {
    transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1; }
  100% {
    transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1; } }

@-webkit-keyframes bigEntrance {
  0% {
    -webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2; }
  30% {
    -webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1; }
  45% {
    -webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1; }
  60% {
    -webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1; }
  75% {
    -webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1; }
  90% {
    -webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1; } }

/*
==============================================
hatch
==============================================
*/
.hatch {
  animation-name: hatch;
  -webkit-animation-name: hatch;
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  visibility: visible !important; }

@keyframes hatch {
  0% {
    transform: rotate(0deg) scaleY(0.6); }
  20% {
    transform: rotate(-2deg) scaleY(1.05); }
  35% {
    transform: rotate(2deg) scaleY(1); }
  50% {
    transform: rotate(-2deg); }
  65% {
    transform: rotate(1deg); }
  80% {
    transform: rotate(-1deg); }
  100% {
    transform: rotate(0deg); } }

@-webkit-keyframes hatch {
  0% {
    -webkit-transform: rotate(0deg) scaleY(0.6); }
  20% {
    -webkit-transform: rotate(-2deg) scaleY(1.05); }
  35% {
    -webkit-transform: rotate(2deg) scaleY(1); }
  50% {
    -webkit-transform: rotate(-2deg); }
  65% {
    -webkit-transform: rotate(1deg); }
  80% {
    -webkit-transform: rotate(-1deg); }
  100% {
    -webkit-transform: rotate(0deg); } }

/*
==============================================
bounce
==============================================
*/
.bounce {
  animation-name: bounce;
  -webkit-animation-name: bounce;
  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%; }

@keyframes bounce {
  0% {
    transform: translateY(0%) scaleY(0.6); }
  60% {
    transform: translateY(-100%) scaleY(1.1); }
  70% {
    transform: translateY(0%) scaleY(0.95) scaleX(1.05); }
  80% {
    transform: translateY(0%) scaleY(1.05) scaleX(1); }
  90% {
    transform: translateY(0%) scaleY(0.95) scaleX(1); }
  100% {
    transform: translateY(0%) scaleY(1) scaleX(1); } }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0%) scaleY(0.6); }
  60% {
    -webkit-transform: translateY(-100%) scaleY(1.1); }
  70% {
    -webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05); }
  80% {
    -webkit-transform: translateY(0%) scaleY(1.05) scaleX(1); }
  90% {
    -webkit-transform: translateY(0%) scaleY(0.95) scaleX(1); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1) scaleX(1); } }

/*
==============================================
pulse
==============================================
*/
.pulse {
  animation-name: pulse;
  -webkit-animation-name: pulse;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.7; }
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.9);
    opacity: 0.7; } }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
    opacity: 0.7; }
  50% {
    -webkit-transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(0.95);
    opacity: 0.7; } }

/*
==============================================
floating
==============================================
*/
.floating {
  animation-name: floating;
  -webkit-animation-name: floating;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes floating {
  0% {
    transform: translateY(0%); }
  50% {
    transform: translateY(8%); }
  100% {
    transform: translateY(0%); } }

@-webkit-keyframes floating {
  0% {
    -webkit-transform: translateY(0%); }
  50% {
    -webkit-transform: translateY(8%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.tossing {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  animation-duration: 2.5s;
  -webkit-animation-duration: 2.5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    transform: rotate(-4deg); }
  50% {
    transform: rotate(4deg); }
  100% {
    transform: rotate(-4deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-4deg); }
  50% {
    -webkit-transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(-4deg); } }

/*
==============================================
pullUp
==============================================
*/
.pullUp {
  animation-name: pullUp;
  -webkit-animation-name: pullUp;
  animation-duration: 1.1s;
  -webkit-animation-duration: 1.1s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%; }

@keyframes pullUp {
  0% {
    transform: scaleY(0.1); }
  40% {
    transform: scaleY(1.02); }
  60% {
    transform: scaleY(0.98); }
  80% {
    transform: scaleY(1.01); }
  100% {
    transform: scaleY(0.98); }
  80% {
    transform: scaleY(1.01); }
  100% {
    transform: scaleY(1); } }

@-webkit-keyframes pullUp {
  0% {
    -webkit-transform: scaleY(0.1); }
  40% {
    -webkit-transform: scaleY(1.02); }
  60% {
    -webkit-transform: scaleY(0.98); }
  80% {
    -webkit-transform: scaleY(1.01); }
  100% {
    -webkit-transform: scaleY(0.98); }
  80% {
    -webkit-transform: scaleY(1.01); }
  100% {
    -webkit-transform: scaleY(1); } }

/*
==============================================
pullDown
==============================================
*/
.pullDown {
  animation-name: pullDown;
  -webkit-animation-name: pullDown;
  animation-duration: 1.1s;
  -webkit-animation-duration: 1.1s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%; }

@keyframes pullDown {
  0% {
    transform: scaleY(0.1); }
  40% {
    transform: scaleY(1.02); }
  60% {
    transform: scaleY(0.98); }
  80% {
    transform: scaleY(1.01); }
  100% {
    transform: scaleY(0.98); }
  80% {
    transform: scaleY(1.01); }
  100% {
    transform: scaleY(1); } }

@-webkit-keyframes pullDown {
  0% {
    -webkit-transform: scaleY(0.1); }
  40% {
    -webkit-transform: scaleY(1.02); }
  60% {
    -webkit-transform: scaleY(0.98); }
  80% {
    -webkit-transform: scaleY(1.01); }
  100% {
    -webkit-transform: scaleY(0.98); }
  80% {
    -webkit-transform: scaleY(1.01); }
  100% {
    -webkit-transform: scaleY(1); } }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    transform: scaleX(0.3); }
  40% {
    transform: scaleX(1.02); }
  60% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
stretchRight
==============================================
*/
.stretchRight {
  animation-name: stretchRight;
  -webkit-animation-name: stretchRight;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%; }

@keyframes stretchRight {
  0% {
    transform: scaleX(0.3); }
  40% {
    transform: scaleX(1.02); }
  60% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(1); } }

@-webkit-keyframes stretchRight {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*============================================================================
  GRID
==============================================================================*/
.grid, .grid--rev, .grid--full, .grid--p50, .grid--p100, .grid--p200 {
  *zoom: 1;
  margin: 0 0 20px -20px; }
  .grid:before, .grid--rev:before, .grid--full:before, .grid--p50:before, .grid--p100:before, .grid--p200:before, .grid:after, .grid--rev:after, .grid--full:after, .grid--p50:after, .grid--p100:after, .grid--p200:after {
    display: table;
    content: " "; }
  .grid:after, .grid--rev:after, .grid--full:after, .grid--p50:after, .grid--p100:after, .grid--p200:after {
    clear: both; }

ul.grid, ul.grid--rev, ul.grid--full, ul.grid--p50, ul.grid--p100, ul.grid--p200,
ul.grid-uniform {
  list-style: none;
  padding: 0; }

.grid__item {
  float: left;
  padding-left: 20px;
  width: 100%; }

.grid--rev {
  direction: rtl;
  text-align: left; }
  .grid--rev > .grid__item {
    direction: ltr;
    text-align: left;
    float: right; }

.grid--full {
  margin-left: 0; }
  .grid--full > .grid__item {
    padding-left: 0; }

@media (min-width: 768px) {
  .grid--p50 {
    margin-left: -25px; }
    .grid--p50 > .grid__item {
      padding-left: 25px; } }

@media (min-width: 1024px) {
  .grid--p50 {
    margin-left: -50px; }
    .grid--p50 > .grid__item {
      padding-left: 50px; } }

@media (min-width: 768px) {
  .grid--p100 {
    margin-left: -50px; }
    .grid--p100 > .grid__item {
      padding-left: 50px; } }

@media (min-width: 1024px) {
  .grid--p100 {
    margin-left: -100px; }
    .grid--p100 > .grid__item {
      padding-left: 100px; } }

@media (min-width: 768px) {
  .grid--p200 {
    margin-left: -100px; }
    .grid--p200 > .grid__item {
      padding-left: 100px; } }

@media (min-width: 1024px) {
  .grid--p200 {
    margin-left: -200px; }
    .grid--p200 > .grid__item {
      padding-left: 200px; } }

.one-whole {
  width: 100%; }

.one-half {
  width: 50%; }

.one-third {
  width: 33.333%; }

.two-thirds {
  width: 66.666%; }

.one-quarter {
  width: 25%; }

.two-quarters {
  width: 50%; }

.three-quarters {
  width: 75%; }

.one-fifth {
  width: 20%; }

.two-fifths {
  width: 40%; }

.three-fifths {
  width: 60%; }

.four-fifths {
  width: 80%; }

.one-sixth {
  width: 16.666%; }

.two-sixths {
  width: 33.333%; }

.three-sixths {
  width: 50%; }

.four-sixths {
  width: 66.666%; }

.five-sixths {
  width: 83.333%; }

.one-eighth {
  width: 12.5%; }

.two-eighths {
  width: 25%; }

.three-eighths {
  width: 37.5%; }

.four-eighths {
  width: 50%; }

.five-eighths {
  width: 62.5%; }

.six-eighths {
  width: 75%; }

.seven-eighths {
  width: 87.5%; }

.one-tenth {
  width: 10%; }

.two-tenths {
  width: 20%; }

.three-tenths {
  width: 30%; }

.four-tenths {
  width: 40%; }

.five-tenths {
  width: 50%; }

.six-tenths {
  width: 60%; }

.seven-tenths {
  width: 70%; }

.eight-tenths {
  width: 80%; }

.nine-tenths {
  width: 90%; }

.one-twelfth {
  width: 8.333%; }

.two-twelfths {
  width: 16.666%; }

.three-twelfths {
  width: 25%; }

.four-twelfths {
  width: 33.333%; }

.five-twelfths {
  width: 41.666%; }

.six-twelfths {
  width: 50%; }

.seven-twelfths {
  width: 58.333%; }

.eight-twelfths {
  width: 66.666%; }

.nine-twelfths {
  width: 75%; }

.ten-twelfths {
  width: 83.333%; }

.eleven-twelfths {
  width: 91.666%; }

.show {
  display: block !important; }

.hide {
  display: none !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.left {
  float: left !important; }

.right {
  float: right !important; }

@media only screen and (min-width: 768px) {
  .medium--one-whole {
    width: 100%; }
  .medium--one-half {
    width: 50%; }
  .medium--one-third {
    width: 33.333%; }
  .medium--two-thirds {
    width: 66.666%; }
  .medium--one-quarter {
    width: 25%; }
  .medium--two-quarters {
    width: 50%; }
  .medium--three-quarters {
    width: 75%; }
  .medium--one-fifth {
    width: 20%; }
  .medium--two-fifths {
    width: 40%; }
  .medium--three-fifths {
    width: 60%; }
  .medium--four-fifths {
    width: 80%; }
  .medium--one-sixth {
    width: 16.666%; }
  .medium--two-sixths {
    width: 33.333%; }
  .medium--three-sixths {
    width: 50%; }
  .medium--four-sixths {
    width: 66.666%; }
  .medium--five-sixths {
    width: 83.333%; }
  .medium--one-eighth {
    width: 12.5%; }
  .medium--two-eighths {
    width: 25%; }
  .medium--three-eighths {
    width: 37.5%; }
  .medium--four-eighths {
    width: 50%; }
  .medium--five-eighths {
    width: 62.5%; }
  .medium--six-eighths {
    width: 75%; }
  .medium--seven-eighths {
    width: 87.5%; }
  .medium--one-tenth {
    width: 10%; }
  .medium--two-tenths {
    width: 20%; }
  .medium--three-tenths {
    width: 30%; }
  .medium--four-tenths {
    width: 40%; }
  .medium--five-tenths {
    width: 50%; }
  .medium--six-tenths {
    width: 60%; }
  .medium--seven-tenths {
    width: 70%; }
  .medium--eight-tenths {
    width: 80%; }
  .medium--nine-tenths {
    width: 90%; }
  .medium--one-twelfth {
    width: 8.333%; }
  .medium--two-twelfths {
    width: 16.666%; }
  .medium--three-twelfths {
    width: 25%; }
  .medium--four-twelfths {
    width: 33.333%; }
  .medium--five-twelfths {
    width: 41.666%; }
  .medium--six-twelfths {
    width: 50%; }
  .medium--seven-twelfths {
    width: 58.333%; }
  .medium--eight-twelfths {
    width: 66.666%; }
  .medium--nine-twelfths {
    width: 75%; }
  .medium--ten-twelfths {
    width: 83.333%; }
  .medium--eleven-twelfths {
    width: 91.666%; }
  .medium--show {
    display: block !important; }
  .medium--hide {
    display: none !important; }
  .medium--text-left {
    text-align: left !important; }
  .medium--text-right {
    text-align: right !important; }
  .medium--text-center {
    text-align: center !important; }
  .medium--left {
    float: left !important; }
  .medium--right {
    float: right !important; } }

@media only screen and (min-width: 1024px) {
  .large--one-whole {
    width: 100%; }
  .large--one-half {
    width: 50%; }
  .large--one-third {
    width: 33.333%; }
  .large--two-thirds {
    width: 66.666%; }
  .large--one-quarter {
    width: 25%; }
  .large--two-quarters {
    width: 50%; }
  .large--three-quarters {
    width: 75%; }
  .large--one-fifth {
    width: 20%; }
  .large--two-fifths {
    width: 40%; }
  .large--three-fifths {
    width: 60%; }
  .large--four-fifths {
    width: 80%; }
  .large--one-sixth {
    width: 16.666%; }
  .large--two-sixths {
    width: 33.333%; }
  .large--three-sixths {
    width: 50%; }
  .large--four-sixths {
    width: 66.666%; }
  .large--five-sixths {
    width: 83.333%; }
  .large--one-eighth {
    width: 12.5%; }
  .large--two-eighths {
    width: 25%; }
  .large--three-eighths {
    width: 37.5%; }
  .large--four-eighths {
    width: 50%; }
  .large--five-eighths {
    width: 62.5%; }
  .large--six-eighths {
    width: 75%; }
  .large--seven-eighths {
    width: 87.5%; }
  .large--one-tenth {
    width: 10%; }
  .large--two-tenths {
    width: 20%; }
  .large--three-tenths {
    width: 30%; }
  .large--four-tenths {
    width: 40%; }
  .large--five-tenths {
    width: 50%; }
  .large--six-tenths {
    width: 60%; }
  .large--seven-tenths {
    width: 70%; }
  .large--eight-tenths {
    width: 80%; }
  .large--nine-tenths {
    width: 90%; }
  .large--one-twelfth {
    width: 8.333%; }
  .large--two-twelfths {
    width: 16.666%; }
  .large--three-twelfths {
    width: 25%; }
  .large--four-twelfths {
    width: 33.333%; }
  .large--five-twelfths {
    width: 41.666%; }
  .large--six-twelfths {
    width: 50%; }
  .large--seven-twelfths {
    width: 58.333%; }
  .large--eight-twelfths {
    width: 66.666%; }
  .large--nine-twelfths {
    width: 75%; }
  .large--ten-twelfths {
    width: 83.333%; }
  .large--eleven-twelfths {
    width: 91.666%; }
  .large--show {
    display: block !important; }
  .large--hide {
    display: none !important; }
  .large--text-left {
    text-align: left !important; }
  .large--text-right {
    text-align: right !important; }
  .large--text-center {
    text-align: center !important; }
  .large--left {
    float: left !important; }
  .large--right {
    float: right !important; } }

[class*="push--"] {
  position: relative; }

.push--one-whole {
  left: 100%;
  position: relative; }

.push--one-half {
  left: 50%;
  position: relative; }

.push--one-third {
  left: 33.333%;
  position: relative; }

.push--two-thirds {
  left: 66.666%;
  position: relative; }

.push--one-quarter {
  left: 25%;
  position: relative; }

.push--two-quarters {
  left: 50%;
  position: relative; }

.push--three-quarters {
  left: 75%;
  position: relative; }

.push--one-fifth {
  left: 20%;
  position: relative; }

.push--two-fifths {
  left: 40%;
  position: relative; }

.push--three-fifths {
  left: 60%;
  position: relative; }

.push--four-fifths {
  left: 80%;
  position: relative; }

.push--one-sixth {
  left: 16.666%;
  position: relative; }

.push--two-sixths {
  left: 33.333%;
  position: relative; }

.push--three-sixths {
  left: 50%;
  position: relative; }

.push--four-sixths {
  left: 66.666%;
  position: relative; }

.push--five-sixths {
  left: 83.333%;
  position: relative; }

.push--one-eighth {
  left: 12.5%;
  position: relative; }

.push--two-eighths {
  left: 25%;
  position: relative; }

.push--three-eighths {
  left: 37.5%;
  position: relative; }

.push--four-eighths {
  left: 50%;
  position: relative; }

.push--five-eighths {
  left: 62.5%;
  position: relative; }

.push--six-eighths {
  left: 75%;
  position: relative; }

.push--seven-eighths {
  left: 87.5%;
  position: relative; }

.push--one-tenth {
  left: 10%;
  position: relative; }

.push--two-tenths {
  left: 20%;
  position: relative; }

.push--three-tenths {
  left: 30%;
  position: relative; }

.push--four-tenths {
  left: 40%;
  position: relative; }

.push--five-tenths {
  left: 50%;
  position: relative; }

.push--six-tenths {
  left: 60%;
  position: relative; }

.push--seven-tenths {
  left: 70%;
  position: relative; }

.push--eight-tenths {
  left: 80%;
  position: relative; }

.push--nine-tenths {
  left: 90%;
  position: relative; }

.push--one-twelfth {
  left: 8.333%;
  position: relative; }

.push--two-twelfths {
  left: 16.666%;
  position: relative; }

.push--three-twelfths {
  left: 25%;
  position: relative; }

.push--four-twelfths {
  left: 33.333%;
  position: relative; }

.push--five-twelfths {
  left: 41.666%;
  position: relative; }

.push--six-twelfths {
  left: 50%;
  position: relative; }

.push--seven-twelfths {
  left: 58.333%;
  position: relative; }

.push--eight-twelfths {
  left: 66.666%;
  position: relative; }

.push--nine-twelfths {
  left: 75%;
  position: relative; }

.push--ten-twelfths {
  left: 83.333%;
  position: relative; }

.push--eleven-twelfths {
  left: 91.666%;
  position: relative; }

@media only screen and (min-width: 768px) {
  .push--medium--one-whole {
    left: 100%;
    position: relative; }
  .push--medium--one-half {
    left: 50%;
    position: relative; }
  .push--medium--one-third {
    left: 33.333%;
    position: relative; }
  .push--medium--two-thirds {
    left: 66.666%;
    position: relative; }
  .push--medium--one-quarter {
    left: 25%;
    position: relative; }
  .push--medium--two-quarters {
    left: 50%;
    position: relative; }
  .push--medium--three-quarters {
    left: 75%;
    position: relative; }
  .push--medium--one-fifth {
    left: 20%;
    position: relative; }
  .push--medium--two-fifths {
    left: 40%;
    position: relative; }
  .push--medium--three-fifths {
    left: 60%;
    position: relative; }
  .push--medium--four-fifths {
    left: 80%;
    position: relative; }
  .push--medium--one-sixth {
    left: 16.666%;
    position: relative; }
  .push--medium--two-sixths {
    left: 33.333%;
    position: relative; }
  .push--medium--three-sixths {
    left: 50%;
    position: relative; }
  .push--medium--four-sixths {
    left: 66.666%;
    position: relative; }
  .push--medium--five-sixths {
    left: 83.333%;
    position: relative; }
  .push--medium--one-eighth {
    left: 12.5%;
    position: relative; }
  .push--medium--two-eighths {
    left: 25%;
    position: relative; }
  .push--medium--three-eighths {
    left: 37.5%;
    position: relative; }
  .push--medium--four-eighths {
    left: 50%;
    position: relative; }
  .push--medium--five-eighths {
    left: 62.5%;
    position: relative; }
  .push--medium--six-eighths {
    left: 75%;
    position: relative; }
  .push--medium--seven-eighths {
    left: 87.5%;
    position: relative; }
  .push--medium--one-tenth {
    left: 10%;
    position: relative; }
  .push--medium--two-tenths {
    left: 20%;
    position: relative; }
  .push--medium--three-tenths {
    left: 30%;
    position: relative; }
  .push--medium--four-tenths {
    left: 40%;
    position: relative; }
  .push--medium--five-tenths {
    left: 50%;
    position: relative; }
  .push--medium--six-tenths {
    left: 60%;
    position: relative; }
  .push--medium--seven-tenths {
    left: 70%;
    position: relative; }
  .push--medium--eight-tenths {
    left: 80%;
    position: relative; }
  .push--medium--nine-tenths {
    left: 90%;
    position: relative; }
  .push--medium--one-twelfth {
    left: 8.333%;
    position: relative; }
  .push--medium--two-twelfths {
    left: 16.666%;
    position: relative; }
  .push--medium--three-twelfths {
    left: 25%;
    position: relative; }
  .push--medium--four-twelfths {
    left: 33.333%;
    position: relative; }
  .push--medium--five-twelfths {
    left: 41.666%;
    position: relative; }
  .push--medium--six-twelfths {
    left: 50%;
    position: relative; }
  .push--medium--seven-twelfths {
    left: 58.333%;
    position: relative; }
  .push--medium--eight-twelfths {
    left: 66.666%;
    position: relative; }
  .push--medium--nine-twelfths {
    left: 75%;
    position: relative; }
  .push--medium--ten-twelfths {
    left: 83.333%;
    position: relative; }
  .push--medium--eleven-twelfths {
    left: 91.666%;
    position: relative; } }

@font-face {
  font-family: 'Optima';
  src: url("../fonts/optima/Optima-Regular.eot");
  src: url("../fonts/optima/Optima-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/optima/Optima-Regular.woff2") format("woff2"), url("../fonts/optima/Optima-Regular.woff") format("woff"), url("../fonts/optima/Optima-Regular.ttf") format("truetype"), url("../fonts/optima/Optima-Regular.svg#Optima-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'TrebuchetMS';
  src: url("../fonts/trebuchet/TrebuchetMS.eot");
  src: url("../fonts/trebuchet/TrebuchetMS.eot?#iefix") format("embedded-opentype"), url("../fonts/trebuchet/TrebuchetMS.woff2") format("woff2"), url("../fonts/trebuchet/TrebuchetMS.woff") format("woff"), url("../fonts/trebuchet/TrebuchetMS.ttf") format("truetype"), url("../fonts/trebuchet/TrebuchetMS.svg#TrebuchetMS") format("svg");
  font-weight: normal;
  font-style: normal; }

/*============================================================================
  TYPOGRAPHY
==============================================================================*/
p {
  line-height: 26px;
  line-height: 2.6rem;
  margin-bottom: 1.5em; }

strong {
  font-weight: 600;
  font-size: inherit; }

em {
  font-style: italic;
  font-weight: inherit; }

a {
  font-weight: inherit;
  font-style: inherit;
  color: #4a4a4a;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  text-decoration: underline;
  text-decoration-skip: ink; }
  a:hover, a:focus {
    color: #777777; }
  a:focus, a:active {
    outline: 0; }
  a.td-n {
    text-decoration: none; }
    a.td-n:hover, a.td-n:focus {
      text-decoration: underline; }

/*a[href^=tel]{
  text-decoration:none;
  &:hover, &:focus {
    text-decoration: underline;
  }
}*/
a:not([href]) {
  color: #4a4a4a;
  text-decoration: none; }

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: 'Optima', Museo Sans, Helvetica, Arial, Verdana, sans-serif;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  color: #4a4a4a;
  line-height: 1.2em;
  margin: 0 0 0.8em; }
  h1.heading--accent, .h1.heading--accent, h2.heading--accent, .h2.heading--accent, h3.heading--accent, .h3.heading--accent, h4.heading--accent, .h4.heading--accent, h5.heading--accent, .h5.heading--accent, h6.heading--accent, .h6.heading--accent {
    color: #3eb1c8; }
  h1 a, .h1 a, h2 a, .h2 a, h3 a, .h3 a, h4 a, .h4 a, h5 a, .h5 a, h6 a, .h6 a {
    text-decoration: none;
    outline: 0;
    font-weight: inherit;
    color: #3eb1c8; }
    h1 a:hover, h1 a:focus, .h1 a:hover, .h1 a:focus, h2 a:hover, h2 a:focus, .h2 a:hover, .h2 a:focus, h3 a:hover, h3 a:focus, .h3 a:hover, .h3 a:focus, h4 a:hover, h4 a:focus, .h4 a:hover, .h4 a:focus, h5 a:hover, h5 a:focus, .h5 a:hover, .h5 a:focus, h6 a:hover, h6 a:focus, .h6 a:hover, .h6 a:focus {
      color: #006a8e; }

h1, .h1 {
  font-size: 28px;
  line-height: 32px;
  font-weight: 200; }
  @media screen and (min-width: 768px) {
    h1, .h1 {
      font-size: calc(28px + 28 * (100vw - 768px) / 432); } }
  @media screen and (min-width: 1200px) {
    h1, .h1 {
      font-size: 56px; } }
  @media screen and (min-width: 768px) {
    h1, .h1 {
      line-height: calc(32px + 32 * (100vw - 768px) / 432); } }
  @media screen and (min-width: 1200px) {
    h1, .h1 {
      line-height: 64px; } }

h2, .h2 {
  font-family: 'TrebuchetMS', Helvetica, Arial, Verdana, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5em;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  font-size: 26px;
  color: #006a8e; }
  @media screen and (min-width: 768px) {
    h2, .h2 {
      font-size: calc(26px + 10 * (100vw - 768px) / 432); } }
  @media screen and (min-width: 1200px) {
    h2, .h2 {
      font-size: 36px; } }

h3, .h3 {
  font-size: 26px; }
  @media screen and (min-width: 768px) {
    h3, .h3 {
      font-size: calc(26px + 4 * (100vw - 768px) / 256); } }
  @media screen and (min-width: 1024px) {
    h3, .h3 {
      font-size: 30px; } }

h4, .h4 {
  font-size: 16px;
  font-size: 1.6rem; }

@media (min-width: 768px) {
  .v-center-heading-tov-visual {
    width: 100%;
    position: relative;
    height: 0;
    padding-bottom: 73%;
    display: block; }
    .v-center-heading-tov-visual .v-center-heading-tov-visual__item {
      position: absolute;
      top: 50%;
      transform: translateY(-50%); } }

.heading-with-label__label {
  text-transform: uppercase;
  font-size: 13px;
  font-size: 1.3rem;
  letter-spacing: 0.15em;
  line-height: 19px;
  line-height: 1.9rem;
  color: #bebebe;
  margin-bottom: 20px; }

.heading-with-label__heading {
  color: #4a4a4a; }

ul, ol {
  margin: 0 0 1.5em 16px; }
  ul li, ol li {
    line-height: 1.5em;
    padding: 0; }

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0; }

ul {
  list-style: disc; }
  ul ul {
    list-style: circle; }
  ul.lined li {
    border-bottom: 1px solid #E3E3E3;
    padding: 5px 0; }
    ul.lined li:first-child {
      padding-top: 0; }
    ul.lined li:last-child {
      padding-bottom: 0;
      border-bottom: none; }

ol {
  list-style: decimal; }

.unstyled, ul.lined, nav ul {
  margin: 0 0 1.5em;
  padding: 0;
  list-style: none; }
  .unstyled ul, ul.lined ul, nav ul ul {
    list-style: none; }
  .unstyled li, ul.lined li, nav ul li {
    list-style: none;
    list-style-image: none;
    padding: 0;
    background: none; }

nav ul {
  *zoom: 1; }
  nav ul:before, nav ul:after {
    display: table;
    content: " "; }
  nav ul:after {
    clear: both; }

dl {
  margin: 0 0 1.5em; }

dt, dd {
  line-height: 1.5em; }

dt {
  font-weight: 600; }

dd {
  margin-bottom: 0.5em; }

.heading-shadow, .header__content h1 {
  text-shadow: 0 5px 10px #4a4a4a; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #bebebe;
  margin: 50px 0; }

blockquote {
  line-height: inherit;
  padding-left: 20px;
  border-left: 1px solid #bebebe;
  margin-bottom: 1.5em;
  font-size: 20px;
  font-size: 2rem;
  font-style: italic; }
  blockquote p {
    margin-bottom: 0.5em; }
  blockquote cite {
    font-size: 14px;
    font-size: 1.4rem; }
  @media (min-width: 768px) {
    blockquote {
      padding-left: 40px; } }

@font-face {
  font-family: 'esign';
  src: url("../fonts/esign.eot?vbjx8w");
  src: url("../fonts/esign.eot?vbjx8w#iefix") format("embedded-opentype"), url("../fonts/esign.ttf?vbjx8w") format("truetype"), url("../fonts/esign.woff?vbjx8w") format("woff"), url("../fonts/esign.svg?vbjx8w#esign") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"], .icon, .arrowed-link:after, .news-detail__navigation__overview-link:after, .button--scroll:before, .has-subnav > a:before, .language__trigger:before, .open-video {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'esign' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-up:before {
  content: "\e914"; }

.icon-arrow-round:before {
  content: "\e915"; }

.icon-fb:before {
  content: "\e916"; }

.icon-ln:before {
  content: "\e917"; }

.icon-long-arrow:before {
  content: "\e918"; }

.icon-play:before, .open-video:before {
  content: "\e919"; }

.icon-plus:before {
  content: "\e91a"; }

.icon-scan:before {
  content: "\e91b"; }

.icon-twitter:before {
  content: "\e91c"; }

.icon-surgeon:before {
  content: "\e91d"; }

.icon-short-arrow:before {
  content: "\e91e"; }

.icon-surgery:before {
  content: "\e91f"; }

.icon-syringe:before {
  content: "\e920"; }

.icon-talk:before {
  content: "\e921"; }

.icon-toothbrush:before {
  content: "\e922"; }

.icon-visit:before {
  content: "\e923"; }

.icon-dental:before {
  content: "\e924"; }

.icon-design:before {
  content: "\e925"; }

.icon-parameters:before {
  content: "\e926"; }

.icon-pills:before {
  content: "\e927"; }

.icon-person:before {
  content: "\e928"; }

.icon-facebook:before {
  content: "\ea91"; }

.icon-linkedin:before {
  content: "\eacb"; }

.icon-facebook:before {
  content: "\ea90"; }

.icon-linkedin:before {
  content: "\eaca"; }

/*============================================================================
  TABLES
==============================================================================*/
table {
  width: 100%;
  padding: 0;
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  margin: 0 0 1.5em; }
  table th,
  table td {
    padding: 11px 6px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid #ddd;
    line-height: 1.5em; }
  table th {
    font-weight: bold;
    border-bottom: 2px solid #ddd; }
  table .table-th-btn {
    width: 155px; }

/*============================================================================
  FORMS
==============================================================================*/
form {
  margin: 0; }

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
  font-size: 18px; }

button, input, select, textarea, .button {
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

button, input {
  line-height: normal; }

button, select {
  text-transform: none; }

label, .label, input, select, textarea {
  font-family: 'TrebuchetMS', Helvetica, Arial, Verdana, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5em;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  vertical-align: middle;
  color: #4a4a4a; }

label, .label {
  display: block;
  margin-bottom: 8px; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
  border: none; }

input[type="checkbox"], input[type="radio"] {
  cursor: pointer; }

input[type="text"], input[type="search"], input[type="password"],
input[type="email"], input[type="tel"], input[type="date"], textarea, select {
  padding: 6px;
  margin-bottom: 2px;
  display: block;
  background: #fff;
  height: 35px;
  line-height: inherit;
  width: 100%;
  border: 1px solid #bebebe; }
  input[type="text"]:focus, input[type="search"]:focus, input[type="password"]:focus,
  input[type="email"]:focus, input[type="tel"]:focus, input[type="date"]:focus, textarea:focus, select:focus {
    outline: 0;
    border-color: #777777; }

input[type="email"] {
  display: block; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  width: 100%;
  height: 185px; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding: 0;
  margin: 3px 0;
  *margin-top: 0;
  *height: 13px;
  *width: 13px;
  line-height: normal;
  border: none; }

input[type="file"] {
  background-color: #fff;
  padding: initial;
  border: initial;
  line-height: initial; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

.arrowed-link, .news-detail__navigation__overview-link {
  *zoom: 1;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  display: inline-block;
  color: #006a8e; }
  .arrowed-link:before, .news-detail__navigation__overview-link:before, .arrowed-link:after, .news-detail__navigation__overview-link:after {
    display: table;
    content: " "; }
  .arrowed-link:after, .news-detail__navigation__overview-link:after {
    clear: both; }
  .arrowed-link:hover, .news-detail__navigation__overview-link:hover, .arrowed-link:focus, .news-detail__navigation__overview-link:focus, .arrowed-link:active, .news-detail__navigation__overview-link:active {
    color: #3eb1c8; }
    .arrowed-link:hover:after, .news-detail__navigation__overview-link:hover:after, .arrowed-link:focus:after, .news-detail__navigation__overview-link:focus:after, .arrowed-link:active:after, .news-detail__navigation__overview-link:active:after {
      transform: translateX(10px); }
  .arrowed-link:after, .news-detail__navigation__overview-link:after {
    float: right;
    content: "\e918";
    margin-top: 2px;
    margin-left: 10px;
    font-size: 8px;
    font-size: 0.8rem;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

.arrowed-link--back {
  color: #006a8e; }
  .arrowed-link--back:after {
    float: left;
    transform: rotate(180deg);
    margin-left: 0;
    margin-right: 10px; }
  .arrowed-link--back:hover:after, .arrowed-link--back:focus:after, .arrowed-link--back:active:after {
    transform: rotate(180deg) translateX(10px);
    margin-left: 0px; }

button, input[type="reset"], input[type="submit"], .button {
  float: left;
  display: block;
  clear: both;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  outline: 0;
  line-height: 1.2em;
  padding: 15px 20px;
  margin: 10px 0 0;
  background-color: #006a8e;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0px;
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 0.15em;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased; }
  button i, input[type="reset"] i, input[type="submit"] i, .button i {
    margin: -5px 6px 0 5px; }
  button:hover, button:focus, button:active, input[type="reset"]:hover, input[type="reset"]:focus, input[type="reset"]:active, input[type="submit"]:hover, input[type="submit"]:focus, input[type="submit"]:active, .button:hover, .button:focus, .button:active {
    background-color: #3eb1c8;
    color: #fff; }
  button:active, input[type="reset"]:active, input[type="submit"]:active, .button:active {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    transform: translateY(1px); }
  button::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner, .button::-moz-focus-inner {
    border: 0; }

.button--skew {
  position: relative;
  border: 1px solid #006a8e;
  border-left: none;
  display: inline-block;
  float: none;
  color: #fff;
  z-index: 11;
  margin-left: 20px;
  background-color: #006a8e; }
  .button--skew:before {
    content: '';
    border: 1px solid #006a8e;
    background-color: #006a8e;
    border-right: none;
    width: 20px;
    height: calc(100% + 2px);
    margin-top: -1px;
    transform: skew(-20deg);
    position: absolute;
    left: 0;
    top: 0;
    margin-left: -10px;
    z-index: -1;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .button--skew:hover, .button--skew:focus, .button--skew:active {
    background-color: #3eb1c8;
    border-color: #3eb1c8; }
    .button--skew:hover:before, .button--skew:focus:before, .button--skew:active:before {
      background-color: #3eb1c8;
      border-color: #3eb1c8; }
  .button--skew.button--skew-right {
    padding-left: 20px;
    padding-right: 10px;
    margin-right: 20px;
    float: none;
    margin-left: 0; }
    .button--skew.button--skew-right:before {
      left: auto;
      right: 0;
      margin-left: 0;
      margin-right: -10px;
      transform: skew(-20deg); }

.button--white {
  border-color: #006a8e;
  color: #006a8e;
  background-color: #fff; }
  .button--white:before {
    background-color: #fff; }
  .button--white:hover, .button--white:focus, .button--white:active {
    border-color: #3eb1c8;
    color: #fff;
    background-color: #3eb1c8; }
    .button--white:hover:before, .button--white:focus:before, .button--white:active:before {
      background-color: #3eb1c8;
      border-color: #3eb1c8; }

.button__content {
  /*background-color: $white;
  position: relative;
  z-index: 99;
  width: 100%;
  height: 100%;*/ }

button, input[type="text"], input[type="submit"], input[type="button"], input[type="reset"],
input[type="email"], input[type="tel"], textarea {
  -webkit-appearance: none; }

button:focus, input[type="reset"]:focus, input[type="submit"]:focus, .button:focus, a.button:focus, select:focus {
  outline: 0;
  *outline: inherit;
  box-shadow: 0 0 1px 1px rgba(200, 200, 200, 0.1); }

.button-social {
  margin: 10px;
  display: block;
  border-radius: 0;
  position: relative; }
  .button-social i {
    color: #006a8e;
    font-size: 20px;
    font-size: 2rem;
    position: absolute;
    display: block;
    top: 50%;
    margin: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
    .button-social i.icon-twitter {
      font-size: 16px;
      font-size: 1.6rem; }
  .button-social:hover i, .button-social:focus i, .button-social:active i {
    color: #3eb1c8;
    margin: 0; }

.button--scroll {
  position: relative;
  width: 40px;
  height: 40px;
  background: transparent;
  display: block;
  float: none;
  margin: 0 auto;
  top: 25px;
  text-align: center; }
  .button--scroll:before {
    content: "\e914";
    font-size: 16px;
    font-size: 1.6rem;
    color: #3eb1c8;
    position: absolute;
    margin-left: 2px;
    margin-top: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .button--scroll:hover, .button--scroll:focus {
    background: transparent; }
  @media (min-width: 768px) {
    .button--scroll {
      top: 50px; } }

::placeholder {
  color: #969696;
  font-style: normal;
  font-weight: 400; }

::-ms-clear {
  display: none; }

input[type="search"] {
  -webkit-appearance: none; }

::-webkit-search-cancel-button {
  -webkit-appearance: none; }

::-webkit-search-results-button {
  -webkit-appearance: none; }

::-webkit-inner-spin-button {
  -webkit-appearance: none; }

::-webkit-outer-spin-button {
  -webkit-appearance: none; }

form .row {
  margin-bottom: 0; }

fieldset {
  width: auto; }
  fieldset p, fieldset ul, fieldset li {
    *zoom: 1; }
    fieldset p:before, fieldset p:after, fieldset ul:before, fieldset ul:after, fieldset li:before, fieldset li:after {
      display: table;
      content: " "; }
    fieldset p:after, fieldset ul:after, fieldset li:after {
      clear: both; }
  fieldset ul {
    margin-left: -20px; }
    fieldset ul li {
      list-style: none;
      background: none;
      list-style-image: none;
      margin: 0 0 15px;
      float: left;
      width: 100%;
      padding: 0 0 0 20px; }
      fieldset ul li.error input {
        border-color: #f1062c; }
      fieldset ul li.space {
        margin-top: 25px; }
      @media (min-width: 768px) {
        fieldset ul li.one-half {
          width: 50%; }
        fieldset ul li.one-fourth {
          width: 25%; } }

.buttons {
  *zoom: 1; }
  .buttons:before, .buttons:after {
    display: table;
    content: " "; }
  .buttons:after {
    clear: both; }
  .buttons button, .buttons input[type="reset"], .buttons input[type="submit"], .buttons .button, .buttons a.button {
    float: left;
    clear: none;
    margin-right: 15px; }
    .buttons button.right, .buttons input[type="reset"].right, .buttons input[type="submit"].right, .buttons .button.right, .buttons a.button.right {
      float: right;
      margin-right: 0;
      margin-left: 15px; }

.a-c .button, .a-c a.button, .a-c button {
  float: none;
  clear: none;
  display: inline-block; }

.grecaptcha-badge {
  display: none; }

.floating-label-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  font-weight: 600; }
  .floating-label-container label {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 600;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    white-space: nowrap;
    width: auto;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s; }
    .floating-label-container label.active {
      display: block;
      visibility: visible;
      opacity: 1;
      font-size: 9px;
      font-size: 0.9rem;
      transform: translate(1em, 0.5em); }
  .floating-label-container input, .floating-label-container textarea {
    border: 1px solid #006a8e;
    font-weight: 600;
    color: #006a8e;
    padding-left: 15px; }
  .floating-label-container input {
    height: 45px;
    width: 100%; }
    .floating-label-container input.active {
      padding-top: 15px;
      border-bottom: 3px solid #006a8e; }
  .floating-label-container textarea.active {
    padding-top: 25px; }
  .floating-label-container.floating-label-container--above {
    margin-top: 1.4em; }
    .floating-label-container.floating-label-container--above label.active {
      transform: translate(0em, -2em); }
    .floating-label-container.floating-label-container--above input {
      height: 35px; }
      .floating-label-container.floating-label-container--above input.active {
        padding-top: 6px; }
  .floating-label-container.floating-label-container--error input {
    border: 1px solid red; }
  .floating-label-container select {
    border: 1px solid #006A8E;
    color: #969696;
    padding-left: 15px;
    height: 45px;
    width: 100%; }

.accept_data-wrap {
  display: flex;
  align-items: flex-start; }
  .accept_data-wrap label, .accept_data-wrap a {
    color: #9A9A9A; }
  .accept_data-wrap label, .accept_data-wrap input[type="checkbox"] {
    display: inline-block; }
  .accept_data-wrap label {
    margin-left: 20px;
    line-height: 20px;
    line-height: 2rem; }
  .accept_data-wrap input[type="checkbox"] {
    position: relative;
    -webkit-appearance: none;
    width: 20px;
    padding: 0;
    margin: 0;
    flex-shrink: 0;
    height: 20px;
    border: 1px solid #006a8e; }
    .accept_data-wrap input[type="checkbox"]:after {
      content: '';
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      top: 0;
      margin-top: 2px;
      margin-left: 2px;
      left: 0; }
    .accept_data-wrap input[type="checkbox"]:checked:after {
      background-color: #006a8e; }

.accept_data_saveformError.formError .formErrorArrow {
  margin-left: 15px; }

.formError {
  z-index: 990; }

.formError .formErrorContent {
  z-index: 991; }

.formError .formErrorArrow {
  z-index: 996; }

.formErrorInsideDialog.formError {
  z-index: 5000; }

.formErrorInsideDialog.formError .formErrorContent {
  z-index: 5001; }

.formErrorInsideDialog.formError .formErrorArrow {
  z-index: 5006; }

.inputContainer {
  position: relative;
  float: left; }

.formError, .formError * {
  box-sizing: content-box; }

.formError {
  position: absolute;
  top: 300px;
  left: 300px;
  display: block;
  cursor: pointer;
  margin: 0 0 0 -128px; }

.ajaxSubmit {
  padding: 20px;
  background: #55ea55;
  border: 1px solid #999;
  display: none; }

.formError .formErrorContent {
  background: #f1062c;
  position: relative;
  color: #fff;
  width: 150px;
  border: 1px solid #f1062c;
  padding: 4px 10px 4px 10px;
  font-size: 14px;
  font-size: 1.4rem;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px; }

.formError .formErrorArrow {
  /*width: 15px; margin: -2px 0 0 13px;*/
  position: relative;
  margin: -2px 0 0 145px;
  height: 10px; }
  .formError .formErrorArrow:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    border: 8px solid transparent;
    border-bottom: 0;
    border-top-color: #f1062c; }

.formError .formErrorArrowBottom {
  box-shadow: none;
  margin: 0px 0 0 12px;
  top: 2px; }

.formError .formErrorArrow div {
  display: none; }

body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #fff;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: 0.8;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s ease 0s, visibility 0s ease 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle;
  border: 1px solid #006a8e;
  box-shadow: 0 0 20px rgba(0, 106, 142, 0.3); }

.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:hover {
  color: #fff; }

.fancybox-button:focus {
  outline: none; }

.fancybox-button.fancybox-focus {
  outline: 1px dotted; }

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none; }

/* Fix IE11 */
.fancybox-button div {
  height: 100%; }

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%; }

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0; }

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none; }

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none; }

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0;
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: linear;
  z-index: 99998; }

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401; }

.fancybox-close-small:hover {
  background-color: rgba(0, 106, 142, 0.1);
  color: #fff;
  opacity: 1; }

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0; }

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none; }

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px; }

.fancybox-navigation .fancybox-button div {
  padding: 7px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  padding: 31px 26px 31px 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0; }

/* Caption */
.fancybox-caption {
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 25px 44px 25px 44px;
  right: 0;
  text-align: center;
  z-index: 99996; }

.fancybox-caption::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAD6CAQAAADKSeXYAAAAYklEQVQoz42RwQ3AMAgDjfcfup8WoRykfBAK5mQHKSz5rbXJPis1hjiV3CIqgG0hLZPkVkA4p4x5oR1bVeDrdCLrW2Q0D5bcwY3TGMHbdw3mPRuOtaspYP1w//G1OIcW148H0DMCqI/3mMMAAAAASUVORK5CYII=);
  background-repeat: repeat-x;
  background-size: contain;
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -44px;
  z-index: -1; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
.fancybox-loading {
  -webkit-animation: fancybox-rotate 1s linear infinite;
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999; }

@-webkit-keyframes fancybox-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes fancybox-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1); }

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-caption {
    padding: 12px; }
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px; }
  .fancybox-slide--image {
    padding: 6px 0; }
  .fancybox-close-small {
    right: -6px; }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px; } }

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs__list a:focus::before {
  opacity: .5; }

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px); } }

/* =============================================================================
   LAYOUT
   ========================================================================== */
html {
  font-size: 62.5%; }

body {
  font-family: 'TrebuchetMS', Helvetica, Arial, Verdana, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5em;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  color: #777777;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0); }

.page-wrap {
  position: relative; }
  @media (min-width: 768px) {
    .page-wrap {
      min-height: 100vh;
      z-index: 2; } }

.container {
  position: relative;
  max-width: 1480px;
  margin: 0 auto;
  padding: 0 20px; }
  @media (min-width: 1024px) {
    .container {
      padding-left: 40px;
      padding-right: 40px; } }

.container--large {
  max-width: 1800px;
  padding-left: 0;
  padding-right: 0; }

.container--static {
  position: static; }

.wf-loading {
  overflow: hidden; }
  .wf-loading body {
    visibility: hidden; }

::selection {
  background-color: #ddf1f5;
  color: inherit; }

/* =============================================================================
   HELPERS
   ========================================================================== */
.icon, .arrowed-link:after, .news-detail__navigation__overview-link:after, .button--scroll:before, .has-subnav > a:before, .language__trigger:before, .open-video {
  display: inline-block;
  vertical-align: middle;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

.icon-hamburger,
.icon-hamburger:before,
.icon-hamburger:after {
  width: 24px;
  position: absolute;
  left: 0;
  height: 1px;
  border-top: 3px solid #3eb1c8;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

.icon-hamburger {
  position: relative; }
  .icon-hamburger:before, .icon-hamburger:after {
    content: "";
    margin-left: 0; }
  .icon-hamburger:before {
    margin-top: 3px; }
  .icon-hamburger:after {
    margin-top: 9px; }

.dib.dib {
  display: inline-block; }

.mb0.mb0 {
  margin-bottom: 0; }

.pb0.pb0 {
  padding-bottom: 0; }

.mb20.mb20 {
  margin-bottom: 20px; }

.mb40.mb40 {
  margin-bottom: 40px; }

.mb60.mb60 {
  margin-bottom: 60px; }

.mb80.mb80 {
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    .mb80.mb80 {
      margin-bottom: 80px; } }

.mb140.mb140 {
  margin-bottom: 140px; }

.a-c.a-c {
  text-align: center; }

.a-r.a-r {
  text-align: right; }

.small--mb20 {
  margin-bottom: 20px; }
  @media (min-width: 1024px) {
    .small--mb20 {
      margin-bottom: 0px; } }

.mb-gutter {
  margin-bottom: 30px; }

@media (max-width: 766px) {
  .mobile--mb-gutter {
    margin-bottom: 30px; } }

@media (min-width: 768px) {
  .medium--mb-dubble-gutter {
    margin-bottom: 60px; } }

.gm-style img {
  max-width: inherit; }

.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px; }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.equal-h {
  display: flex;
  flex-wrap: wrap; }
  .equal-h > * {
    display: flex; }
  .equal-h:before, .equal-h:after {
    display: none; }

@media (min-width: 768px) {
  .medium--equal-h {
    display: flex;
    flex-wrap: wrap; }
    .medium--equal-h > * {
      display: flex; }
    .medium--equal-h:before, .medium--equal-h:after {
      display: none; } }

html.no-flexbox .equal-h {
  height: 100%; }
  html.no-flexbox .equal-h > div {
    float: left; }

@media (min-width: 768px) {
  html.no-flexbox .medium--equal-h {
    height: 100%; }
    html.no-flexbox .medium--equal-h > div {
      float: left; } }

@media (min-width: 768px) {
  .grid--v-centered {
    align-items: center; } }

.grid--v-centered > .grid__item {
  flex-shrink: 0; }

.block-link {
  position: relative;
  cursor: pointer; }

.block-link__target:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

/* =============================================================================
   HEADER
   ========================================================================== */
.page-header {
  *zoom: 1;
  padding: 20px 0; }
  .page-header:before, .page-header:after {
    display: table;
    content: " "; }
  .page-header:after {
    clear: both; }
  @media (min-width: 768px) {
    .page-header {
      padding: 15px 0; } }

.page-header__logo {
  text-decoration: none;
  opacity: 1;
  backface-visibility: hidden;
  float: left; }
  .page-header__logo img {
    display: block;
    width: 130px; }
    @media (min-width: 768px) {
      .page-header__logo img {
        width: 175px;
        margin-top: 10px; } }
    @media (min-width: 1024px) {
      .page-header__logo img {
        margin-top: 20px; } }
    @media (min-width: 1024px) {
      .page-header__logo img {
        width: 250px; } }
  .page-header__logo:hover, .page-header__logo:focus {
    opacity: 0.8;
    backface-visibility: hidden; }

.main-nav {
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  color: #4a4a4a;
  *zoom: 1;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden; }
  .main-nav:before, .main-nav:after {
    display: table;
    content: " "; }
  .main-nav:after {
    clear: both; }
  @media (min-width: 768px) {
    .main-nav {
      float: right;
      width: auto;
      display: block;
      overflow: visible; }
      .main-nav a:not(.button) {
        border-top: 0;
        padding: 0; } }

.mobile-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0; }
  @media (min-width: 1024px) {
    .mobile-header {
      display: none; } }
  .mobile-header .main-nav__trigger {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: 0; }

.mobile-header__logo {
  text-align: center;
  margin-right: 20px; }
  .mobile-header__logo img {
    display: inline-block;
    width: 175px; }
  @media (min-width: 1024px) {
    .mobile-header__logo {
      display: none; } }

.main-nav__wrap__item {
  font-size: 14px;
  font-size: 1.4rem;
  z-index: 999;
  font-weight: 500;
  text-transform: uppercase;
  color: #a0a0a0;
  letter-spacing: 0.1em;
  position: relative; }
  @media (min-width: 1024px) {
    .main-nav__wrap__item:hover .main-nav__subnav {
      max-height: 700px;
      border: 1px solid rgba(160, 160, 160, 0.25);
      padding-bottom: 10px;
      padding-top: 10px; } }
  .main-nav__wrap__item:last-child {
    margin-right: 0; }
  @media (min-width: 1024px) {
    .main-nav__wrap__item {
      float: left;
      margin: 0;
      padding: 0 10px 10px 10px;
      border: none; } }
  @media (min-width: 1200px) {
    .main-nav__wrap__item {
      padding: 0 20px 10px 20px; } }
  .main-nav__wrap__item > a:not(.button) {
    text-decoration: none;
    color: #777777;
    padding: 10px 0;
    display: block; }
    @media (min-width: 1024px) {
      .main-nav__wrap__item > a:not(.button) {
        padding: 0;
        border-top: none;
        border-bottom: none; } }
  .main-nav__wrap__item > a:not(.button):hover,
  .main-nav__wrap__item > a:not(.button):focus,
  .main-nav__wrap__item.active > a {
    color: #3eb1c8;
    font-weight: 600; }
  .main-nav__wrap__item a:after {
    content: attr(data-title);
    visibility: hidden;
    display: block;
    height: 0;
    overflow: hidden;
    font-weight: 600; }

.main-nav__wrap__social-button {
  display: inline-block;
  margin-bottom: 0px; }
  .main-nav__wrap__social-button a.button-social {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0 5px; }
    .main-nav__wrap__social-button a.button-social i {
      font-size: 16px;
      font-size: 1.6rem;
      padding: 3px; }

.main-nav__wrap__item--button {
  padding-right: 0; }
  .main-nav__wrap__item--button .button {
    margin-top: 0;
    color: #fff;
    margin-left: 10px;
    font-size: 14px;
    font-size: 1.4rem; }

.mobile-order-cta {
  display: block;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999; }
  @media (min-width: 1024px) {
    .mobile-order-cta {
      display: none;
      pointer-events: none; } }

.main-nav__trigger {
  display: block;
  padding: 12px 20px 12px 55px;
  background-color: transparent;
  color: #4a4a4a;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 0px;
  right: 20px;
  margin-top: 0;
  border: 1px solid #efefef; }
  .main-nav__trigger .icon-hamburger {
    position: absolute;
    left: 10px;
    top: 18px; }
  .main-nav__trigger span {
    display: inline-block;
    margin-top: 3px; }
  .main-nav__trigger:hover, .main-nav__trigger:focus {
    background-color: #006a8e;
    box-shadow: none;
    color: #fff; }
    .main-nav__trigger:hover .icon-hamburger, .main-nav__trigger:focus .icon-hamburger {
      background-color: #fff;
      border-color: #fff; }
      .main-nav__trigger:hover .icon-hamburger:before, .main-nav__trigger:hover .icon-hamburger:after, .main-nav__trigger:focus .icon-hamburger:before, .main-nav__trigger:focus .icon-hamburger:after {
        border-color: #fff; }
  .main-nav__trigger:active {
    transform: none; }
  @media (min-width: 768px) {
    .main-nav__trigger {
      margin-top: 10px; } }
  @media (min-width: 1024px) {
    .main-nav__trigger {
      display: none; } }

.main-nav__subnav {
  overflow: hidden;
  z-index: 999;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  @media (min-width: 1024px) {
    .main-nav__subnav {
      background-color: #fff;
      max-height: 0;
      padding-left: 0;
      border: 1px solid rgba(160, 160, 160, 0);
      margin-bottom: 0;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 300px;
      display: block; }
      .main-nav__subnav:hover {
        max-height: 700px;
        border: 1px solid rgba(160, 160, 160, 0.25);
        padding-top: 10px; } }
  .main-nav__subnav a:not(.button) {
    text-decoration: none;
    padding: 10px;
    display: block;
    margin-bottom: 0;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
    @media (min-width: 1024px) {
      .main-nav__subnav a:not(.button) {
        padding: 10px 20px; }
        .main-nav__subnav a:not(.button):hover, .main-nav__subnav a:not(.button):focus, .main-nav__subnav a:not(.button):active {
          background-color: #ddf1f5; } }
  .main-nav__subnav ul {
    margin-bottom: 10px;
    margin-left: 0; }
    @media (min-width: 1024px) {
      .main-nav__subnav ul {
        padding-left: 0;
        margin-bottom: 0; } }

.subnav__item {
  margin-bottom: 0; }
  @media (min-width: 1024px) {
    .subnav__item:first-child .subnav__label {
      padding-top: 20px; } }
  .subnav__item a:not(.button) {
    letter-spacing: 0.012em;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 14px;
    line-height: 1.4rem;
    color: #4a4a4a;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
    @media (min-width: 1024px) {
      .subnav__item a:not(.button):hover, .subnav__item a:not(.button):focus, .subnav__item a:not(.button):active {
        background-color: #ddf1f5; } }
  .subnav__item ul {
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

.subnav__label {
  font-size: 10px;
  font-size: 1rem;
  font-style: italic; }
  @media (min-width: 1024px) {
    .subnav__label {
      padding: 10px 20px;
      font-style: normal; } }

.has-subnav > a {
  position: relative;
  display: inline-block; }
  .has-subnav > a:before {
    display: none; }
    @media (min-width: 1024px) {
      .has-subnav > a:before {
        content: "\e914";
        float: right;
        padding-left: 5px;
        transform: rotate(0deg);
        display: inline-block;
        margin-top: 2px;
        font-size: 12px;
        font-size: 1.2rem;
        color: inherit; } }

.main-nav__wrap {
  display: none;
  margin: 0 0 15px;
  width: 100%;
  margin-top: 20px;
  background-color: #f1f9fb;
  border-top: 1px solid #a0a0a0;
  *zoom: 1; }
  .main-nav__wrap:before, .main-nav__wrap:after {
    display: table;
    content: " "; }
  .main-nav__wrap:after {
    clear: both; }
  @media (max-width: 1024px) {
    .main-nav__wrap {
      position: fixed;
      overflow: scroll;
      top: 0;
      left: 0;
      margin: 0;
      bottom: 0;
      right: 0;
      background-color: white;
      background-repeat: repeat;
      z-index: 99;
      padding: 20px; } }
  @media (max-width: 1024px) {
    .main-nav__wrap .button--skew {
      padding: 10px 30px;
      margin-bottom: 20px; }
      .main-nav__wrap .button--skew:before {
        content: none; } }
  @media (min-width: 1024px) {
    .main-nav__wrap {
      border-top: none;
      display: block;
      width: auto;
      background-color: transparent;
      margin: 0 0 0 -20px; } }

.main-nav__wrap__social {
  margin-bottom: 12px;
  border-bottom: 1px solid #a0a0a0;
  width: 100%;
  display: block;
  padding: 15px 0; }
  .main-nav__wrap__social li {
    display: inline-block;
    float: none;
    margin-left: 5px;
    vertical-align: middle; }
    @media (min-width: 1024px) {
      .main-nav__wrap__social li {
        margin-left: 0; } }
  @media (min-width: 1024px) {
    .main-nav__wrap__social {
      text-align: right;
      border-bottom: none;
      padding: 0; } }

.main-nav__wrap__main {
  margin-bottom: 0; }
  @media (min-width: 1024px) {
    .main-nav__wrap__main {
      display: flex;
      align-items: center; } }

.language.language {
  position: relative;
  display: block;
  z-index: 1111;
  margin-top: 20px; }
  @media (min-width: 1024px) {
    .language.language {
      display: inline-block;
      margin-left: 30px !important;
      margin-top: 0; }
      .language.language:hover .language__wrap, .language.language:focus .language__wrap {
        max-height: 700px;
        border: 1px solid rgba(160, 160, 160, 0.25);
        padding-top: 10px; } }

.language__trigger {
  position: relative;
  padding: 6px 0;
  background-color: transparent;
  color: #006a8e;
  margin: 0;
  font-size: 12px;
  font-size: 1.2rem;
  float: none;
  display: inline-block; }
  .language__trigger:before {
    content: "\e914";
    float: right;
    padding-left: 5px;
    display: none;
    font-size: 12px;
    font-size: 1.2rem;
    color: inherit; }
  .language__trigger:hover, .language__trigger:focus {
    background-color: transparent;
    color: #3eb1c8;
    box-shadow: none; }
  @media (min-width: 1024px) {
    .language__trigger:before {
      display: inline-block; }
    .language__trigger:hover + .language__wrap, .language__trigger:focus + .language__wrap {
      max-height: 700px;
      border: 1px solid rgba(160, 160, 160, 0.25);
      padding-top: 10px; } }

.language__wrap {
  z-index: 999;
  list-style: none;
  background-color: #fff;
  border: 1px solid rgba(160, 160, 160, 0);
  padding-left: 0;
  margin-bottom: 0;
  display: inline-block;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .open .language__wrap {
    border: 1px solid rgba(160, 160, 160, 0.25); }
  .language__wrap li {
    display: inline-block; }
    .language__wrap li a {
      display: inline-block;
      padding: 10px 5px !important;
      text-decoration: none; }
  @media (min-width: 1024px) {
    .language__wrap {
      position: absolute;
      top: 100%;
      right: 0;
      max-height: 0;
      display: block;
      overflow: hidden;
      padding-bottom: 10px; }
      .language__wrap li {
        display: block; }
        .language__wrap li a {
          display: block;
          padding: 10px 20px !important; } }

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap; }
  .skip-link:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    font-size: 20px;
    font-size: 2rem; }

.visual-bg {
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center; }

/* =============================================================================
   MAIN
   ========================================================================== */
.main {
  padding: 20px 0;
  padding-top: 0; }
  @media (min-width: 768px) {
    .main {
      padding: 0px 0 440px; } }
  @media (min-width: 1024px) {
    .main {
      padding-bottom: 340px; } }

/* =============================================================================
   FOOTER
   ========================================================================== */
.page-footer {
  position: relative;
  background-color: #006a8e;
  overflow: hidden;
  *zoom: 1;
  font-weight: 600; }
  .page-footer:before, .page-footer:after {
    display: table;
    content: " "; }
  .page-footer:after {
    clear: both; }
  .page-footer:before {
    content: none;
    position: absolute;
    height: 600px;
    width: 20%;
    background-color: #fff;
    left: 0;
    top: 0;
    transform: rotate(25deg) translate(-95%, -10%); }
  .page-footer a {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 26px;
    line-height: 2.6rem; }
  .page-footer img {
    margin-bottom: 20px; }
  .page-footer .grid, .page-footer .grid--rev, .page-footer .grid--full, .page-footer .grid--p50, .page-footer .grid--p100, .page-footer .grid--p200 {
    margin-bottom: 0; }
  .page-footer .button-social:hover i, .page-footer .button-social:focus i, .page-footer .button-social:active i {
    color: rgba(255, 255, 255, 0.8); }
  .page-footer .button-social i {
    color: #fff; }
  @media (min-width: 768px) {
    .page-footer {
      height: 400px;
      margin: -400px 0 0;
      z-index: 5; }
      .page-footer:before {
        content: ""; } }
  @media (min-width: 1024px) {
    .page-footer {
      height: 300px;
      margin: -300px 0 0;
      z-index: 5; }
      .page-footer img {
        margin-top: -5px; } }
  @media (min-width: 1200px) {
    .page-footer a {
      font-size: 18px;
      font-size: 1.8rem; }
    .page-footer img {
      margin-top: -10px; }
    .page-footer .grid, .page-footer .grid--rev, .page-footer .grid--full, .page-footer .grid--p50, .page-footer .grid--p100, .page-footer .grid--p200 {
      margin-left: -40px; }
      .page-footer .grid > .grid__item, .page-footer .grid--rev > .grid__item, .page-footer .grid--full > .grid__item, .page-footer .grid--p50 > .grid__item, .page-footer .grid--p100 > .grid__item, .page-footer .grid--p200 > .grid__item {
        padding-left: 40px; } }

.footer-navigation {
  margin-bottom: 0;
  margin-left: 0;
  list-style: none; }
  .footer-navigation li {
    display: inline-block;
    margin-right: 10px;
    font-weight: 300; }
    .footer-navigation li:first-child {
      display: block; }
    .footer-navigation li:last-child {
      margin-right: 0; }
  .footer-navigation a {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 16px;
    line-height: 1.6rem; }

.footer-translations p {
  font-size: 10px;
  font-weight: 100; }

@media (min-width: 1024px) {
  .social-buttons--footer {
    text-align: center; } }

.page-footer__content {
  padding: 20px;
  *zoom: 1;
  color: #fff;
  font-size: 16px;
  font-size: 1.6rem; }
  .page-footer__content:before, .page-footer__content:after {
    display: table;
    content: " "; }
  .page-footer__content:after {
    clear: both; }
  .page-footer__content:after {
    content: "";
    display: block;
    clear: both; }
  .page-footer__content a {
    color: #fff;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    margin-bottom: 10px; }
    .page-footer__content a:hover, .page-footer__content a:focus, .page-footer__content a:active {
      color: #fff; }
  @media (min-width: 768px) {
    .page-footer__content {
      padding-top: 125px;
      margin-left: 10%; }
      .page-footer__content a {
        display: inline-block;
        margin-bottom: 0; } }
  @media (min-width: 1200px) {
    .page-footer__content {
      font-size: 18px;
      font-size: 1.8rem;
      margin-left: 15%; } }

.newsletter-form {
  position: relative;
  margin-bottom: 20px; }
  .newsletter-form ::placeholder {
    color: #fff;
    font-style: normal; }
  .newsletter-form input[type="email"] {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    height: 40px; }
  .newsletter-form button {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 5px;
    margin-top: 5px;
    background-color: #fff;
    padding: 5px;
    color: #3eb1c8;
    width: 30px;
    height: 30px;
    border-radius: 50%; }
    .newsletter-form button i {
      font-size: 10px;
      font-size: 1rem;
      position: relative;
      left: -2px;
      top: 1px; }

.social-buttons {
  list-style: none;
  margin-left: 0; }
  .social-buttons li {
    display: inline-block;
    margin-right: 5px; }
    .social-buttons li:last-child {
      margin-right: 0; }

.footer__heading {
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  font-weight: 600; }

.page-footer__bottom {
  clear: both; }
  .page-footer__bottom ul {
    list-style: none;
    margin-left: 0;
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .page-footer__bottom ul {
        margin-bottom: 0; } }
    .page-footer__bottom ul li {
      display: inline-block;
      color: #fff;
      font-size: 16px;
      font-size: 1.6rem; }
      .page-footer__bottom ul li:after {
        content: "";
        display: inline-block;
        background-color: #fff;
        border-radius: 50%;
        width: 5px;
        height: 5px;
        margin-top: -3px;
        margin-left: 5px;
        margin-right: 5px;
        overflow: hidden;
        top: -3px;
        position: relative; }
      .page-footer__bottom ul li:last-child:after {
        content: none; }
      .page-footer__bottom ul li a {
        color: #fff;
        display: inline-block; }
  @media (min-width: 768px) {
    .page-footer__bottom ul {
      margin-bottom: 0; } }

a.esign {
  width: 80px;
  height: 20px;
  background-image: url("../images/logo-esign-white.svg");
  background-size: 77px 20px;
  background-repeat: no-repeat;
  background-position: top left;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  text-indent: -9999px;
  opacity: 0.6;
  transition-duration: 0.5s;
  display: block;
  outline: 0;
  z-index: 9999;
  margin-bottom: 0; }
  a.esign:hover, a.esign:focus {
    opacity: 1; }
  @media (min-width: 768px) {
    a.esign {
      width: 16px;
      position: absolute;
      right: 45px;
      bottom: 0;
      margin-bottom: 20px; }
      a.esign:hover, a.esign:focus {
        opacity: 1;
        width: 80px; } }

/* =============================================================================
   COMPONENTS
   ========================================================================== */
.large {
  font-size: 20px;
  font-size: 2rem; }

.center-block {
  *zoom: 1;
  text-align: center;
  margin: 20px 0; }
  .center-block:before, .center-block:after {
    display: table;
    content: " "; }
  .center-block:after {
    clear: both; }
  .center-block .button {
    float: none;
    display: inline-block; }

section {
  position: relative; }

article {
  *zoom: 1;
  margin: 0 0 20px; }
  article:before, article:after {
    display: table;
    content: " "; }
  article:after {
    clear: both; }
  article a img {
    display: block;
    opacity: 1;
    backface-visibility: hidden; }
  article a:hover img, article a:focus img {
    opacity: 0.8;
    backface-visibility: hidden; }

.article-detail__image {
  margin-bottom: 1.5em; }

.note {
  *zoom: 1;
  margin: 0 0 20px;
  padding: 15px 20px 0;
  background: #efefef; }
  .note:before, .note:after {
    display: table;
    content: " "; }
  .note:after {
    clear: both; }
  .note ul,
  .note p {
    margin-bottom: 1em; }

.note--success {
  color: #39963a;
  background-color: #eff9ef;
  padding-bottom: 15px; }

.note--error {
  color: #f1062c;
  background-color: #fff7f8; }

.cycle-slideshow {
  width: 100%;
  position: relative; }

.cycle-pager {
  width: 100%;
  text-align: center; }
  .cycle-pager span {
    display: inline-block;
    margin: 0 3px;
    font-size: 36px;
    height: 20px;
    width: 20px;
    line-height: 14px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    overflow: hidden;
    text-indent: -500px;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
    .cycle-pager span:hover, .cycle-pager span:focus {
      transition: none; }
  .cycle-pager span:hover,
  .cycle-pager span:focus,
  .cycle-pager .cycle-pager-active {
    text-indent: 0; }

.map {
  width: 100%;
  height: 350px;
  background: #efefef;
  margin: 0 0 40px; }
  @media (min-width: 768px) {
    .map {
      height: 550px; } }

.social {
  *zoom: 1;
  list-style: none;
  margin: 0 0 2em -5px; }
  .social:before, .social:after {
    display: table;
    content: " "; }
  .social:after {
    clear: both; }

.social__item {
  padding: 0 0 5px 5px;
  float: left; }

.social__link {
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: block;
  border-radius: 40px;
  background-color: #3eb1c8;
  text-decoration: none;
  text-align: center;
  overflow: hidden; }

.social__link--facebook {
  background-color: #3c5b9b; }
  .social__link--facebook:hover, .social__link--facebook:focus {
    background-color: #314a7e; }

.social__link--twitter {
  background-color: #2daae1; }
  .social__link--twitter:hover, .social__link--twitter:focus {
    background-color: #1d94c9; }

.social__link--pinterest {
  background-color: #cb2027; }
  .social__link--pinterest:hover, .social__link--pinterest:focus {
    background-color: #a81a20; }

.social__link--linkedin {
  background-color: #0173b2; }
  .social__link--linkedin:hover, .social__link--linkedin:focus {
    background-color: #015989; }

.section-wrap {
  /*  position: relative;*/
  padding-top: 25px;
  padding-bottom: 25px; }
  @media (min-width: 768px) {
    .section-wrap {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media (min-width: 1024px) {
    .section-wrap {
      padding-top: 100px;
      padding-bottom: 100px; } }

.section-wrap-overflow {
  overflow: hidden; }

@media (min-width: 768px) {
  .section-wrap--hexagon {
    margin-top: 80px;
    margin-bottom: 130px; } }

.section-wrap--grey {
  background-color: #0000000c; }

.section-wrap--first {
  padding-top: 0; }

.section-wrap--last {
  padding-bottom: 0; }

.element-with-shadow {
  box-shadow: 5px 5px 25px rgba(160, 160, 160, 0.5);
  margin-bottom: 20px; }
  .element-with-shadow img {
    width: 100%; }

.img-wrap.medium-w,
.img-wrapper.medium-w {
  text-align: center; }
  .img-wrap.medium-w img,
  .img-wrapper.medium-w img {
    max-width: 500px; }

.img-wrap img,
.img-wrapper img {
  width: 100%; }

.open-video {
  position: relative;
  padding: 0; }
  .open-video:hover:after, .open-video:focus:after, .open-video:active:after {
    transform: translate(-50%, -50%) scale(1.1);
    background-color: #3eb1c8; }
  .open-video:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: #006a8e;
    width: 65px;
    padding-left: 0;
    height: 65px;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .open-video:before {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    font-size: 3rem;
    border-radius: 50%;
    width: 65px;
    padding-left: 0;
    height: 65px;
    color: #fff;
    line-height: 65px;
    line-height: 6.5rem;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
    margin-left: -3px;
    z-index: 11; }

@media (min-width: 768px) {
  .heading-with-button {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; } }

@media (min-width: 768px) {
  .heading-with-button__heading {
    margin-right: 40px; } }

@media (min-width: 1200px) {
  .heading-with-button__heading {
    margin-right: 80px; } }

.hexagon {
  position: relative; }
  .hexagon:before {
    position: absolute;
    left: 0;
    top: 50%;
    width: 90%;
    height: 0;
    padding-bottom: 90%;
    margin-left: -305px;
    margin-top: 10px;
    transform: translateY(-50%);
    display: block;
    z-index: -1;
    background-image: url("../images/hexagon-border-10.svg");
    opacity: 0.5;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }
    @media (min-width: 768px) {
      .hexagon:before {
        content: ""; } }
    @media (min-width: 1024px) {
      .hexagon:before {
        margin-left: -150px; } }
    @media (min-width: 1200px) {
      .hexagon:before {
        margin-left: -200px; } }
    @media (min-width: 1400px) {
      .hexagon:before {
        margin-left: -220px;
        margin-top: -15px; } }

.hexagon--medium:before {
  width: 75%;
  padding-bottom: 75%; }

.hexagon--right:before {
  left: auto;
  right: 0;
  margin-left: 0;
  right: -15%; }

.hexagon--top:before {
  top: 0;
  transform: translateY(-30%); }

@media (min-width: 768px) {
  .hexagon--static-w:before {
    width: 400px;
    height: 400px;
    margin-top: -75px; } }

.hexagon--top-half:before {
  top: 0;
  margin-top: -100px;
  transform: translateY(0); }

.header {
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative; }
  @media (min-width: 768px) {
    .header {
      height: 450px; } }
  @media (min-width: 1024px) {
    .header {
      height: 500px; } }
  @media (min-width: 1200px) {
    .header {
      height: 600px; } }
  @media (min-width: 1400px) {
    .header {
      height: 750px; } }
  @media (min-width: 768px) {
    .header img {
      visibility: hidden; } }

.header-wrap {
  width: 100%;
  *zoom: 1; }
  .header-wrap:before, .header-wrap:after {
    display: table;
    content: " "; }
  .header-wrap:after {
    clear: both; }

@media (min-width: 768px) {
  .header-wrap--right {
    padding-left: 40px; } }

@media (min-width: 1200px) {
  .header-wrap--right {
    padding-left: 80px; } }

.header-wrap--right .header {
  float: right; }

@media (min-width: 768px) {
  .header-wrap--left {
    padding-right: 40px; } }

@media (min-width: 1200px) {
  .header-wrap--left {
    padding-right: 80px; } }

.header-wrap--left .header {
  float: left; }

.push-left {
  padding-left: 40px; }
  @media (min-width: 768px) {
    .push-left {
      padding-left: 40px; } }
  @media (min-width: 1200px) {
    .push-left {
      padding-left: 80px; } }

.header__video {
  height: 300px;
  overflow: hidden;
  display: none; }
  .header__video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  @media (min-width: 480px) {
    .header__video {
      height: 0;
      padding-bottom: 65%; } }
  @media (min-width: 768px) {
    .header__video {
      display: block;
      height: auto;
      padding-bottom: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; } }

.header__buttons {
  display: inline-flex;
  margin-right: 20px; }

.header__video-button {
  height: 65px;
  width: 65px;
  z-index: 1;
  transform: scale(0.73846) translateY(9px);
  transform-origin: top left;
  display: inline-flex;
  align-self: flex-end; }
  @media (min-width: 480px) {
    .header__video-button {
      margin-top: -1px;
      display: inline-block; } }

.header__content {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 20px;
  width: 100%; }
  .header__content h1 {
    color: #fff; }
    @media (max-width: 480px) {
      .header__content h1 {
        font-size: 21px; } }
  @media (min-width: 768px) {
    .header__content {
      margin-bottom: 40px; } }
  .header__content .button {
    display: inline-block;
    float: none; }
    @media (max-width: 480px) {
      .header__content .button {
        font-size: 12px;
        padding: 10px 15px; } }

.after-header-block {
  margin-top: 0px;
  padding: 20px;
  padding-bottom: 0; }
  .after-header-block .h1 {
    margin-top: 10px; }
  @media (min-width: 768px) {
    .after-header-block {
      padding: 0;
      position: relative;
      top: -160px;
      margin-top: 20px;
      margin-bottom: -160px;
      width: 100%;
      display: flex;
      align-items: flex-end; } }

.blue-block {
  padding: 20px;
  margin-bottom: 0px;
  width: auto;
  background-color: #3eb1c8;
  color: #fff;
  z-index: 99;
  margin-bottom: 20px;
  font-weight: 200; }
  @media (min-width: 768px) {
    .blue-block {
      margin-bottom: 0; } }
  .blue-block p {
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .blue-block p {
        font-size: 20px;
        font-size: 2rem;
        line-height: 25px;
        line-height: 2.5rem; } }
    @media (min-width: 1024px) {
      .blue-block p {
        font-size: 25px;
        font-size: 2.5rem;
        line-height: 38px;
        line-height: 3.8rem; } }
    @media (min-width: 1200px) {
      .blue-block p {
        font-size: 36px;
        font-size: 3.6rem;
        line-height: 48px;
        line-height: 4.8rem; } }
  @media (min-width: 768px) {
    .blue-block {
      padding: 40px; } }
  @media (min-width: 1200px) {
    .blue-block {
      padding: 80px;
      max-width: 710px; } }

.cta-block {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .cta-block {
      height: 300px;
      margin-bottom: 0; } }
  @media (min-width: 1024px) {
    .cta-block {
      height: 400px; } }
  @media (min-width: 1400px) {
    .cta-block {
      height: 500px; } }
  .cta-block .heading-with-label__heading,
  .cta-block .heading-with-label__label {
    color: #fff; }
  .cta-block a {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%; }
    .cta-block a:hover .cta-block__visual, .cta-block a:focus .cta-block__visual, .cta-block a:active .cta-block__visual {
      transform: scale(1.1); }
      .cta-block a:hover .cta-block__visual:after, .cta-block a:focus .cta-block__visual:after, .cta-block a:active .cta-block__visual:after {
        opacity: 0.3; }

.cta-block__visual {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  position: absolute;
  top: 0;
  left: 0; }
  .cta-block__visual:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #006a8e;
    opacity: 0;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

@media (max-width: 480px) {
  .cta-block--right-bg {
    background-position: 80%; } }

.cta-block__content {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 20px;
  color: #fff;
  padding: 20px; }
  .cta-block__content a,
  .cta-block__content span {
    color: #fff; }

/* =============================================================================
   PAGES
   ========================================================================== */
/* =================================================
   HOME
   ================================================= */
.news-overview__article {
  margin-bottom: 40px; }
  .news-overview__article .block-link {
    text-decoration: none;
    display: block; }
    .news-overview__article .block-link:hover .news-overview__article__title, .news-overview__article .block-link:focus .news-overview__article__title, .news-overview__article .block-link:active .news-overview__article__title {
      color: #006a8e; }
    .news-overview__article .block-link:hover .news-overview__article__image, .news-overview__article .block-link:focus .news-overview__article__image, .news-overview__article .block-link:active .news-overview__article__image {
      transform: scale(1.1); }
      .news-overview__article .block-link:hover .news-overview__article__image:after, .news-overview__article .block-link:focus .news-overview__article__image:after, .news-overview__article .block-link:active .news-overview__article__image:after {
        opacity: 0.25; }
  @media (min-width: 768px) {
    .news-overview__article {
      margin-bottom: 80px; } }

.home .news-overview__article {
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .home .news-overview__article {
      margin-bottom: 0; } }

.news-overview__article__image-wrap {
  overflow: hidden;
  width: 100%;
  padding-bottom: 72.2891566%;
  height: 0;
  display: block;
  margin-bottom: 10px; }

.news-overview__article__image {
  width: 100%;
  padding-bottom: 72.2891566%;
  text-transform: uppercase;
  display: block;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  background-size: cover;
  background-position: center;
  position: relative; }
  .news-overview__article__image:after {
    content: "";
    background-color: #006a8e;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

.news-overview__article__title {
  text-transform: none;
  font-size: 24px;
  font-size: 2.4rem;
  color: #3eb1c8; }

.news-overview__article__description {
  font-size: 16px;
  font-size: 1.6rem; }

.news-overview__article__date {
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #777777;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  letter-spacing: 0.015em; }

.pagination .pagination-pager,
.pagination p > a {
  text-decoration: none;
  color: #006a8e;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 600; }
  .pagination .pagination-pager:hover, .pagination .pagination-pager:focus, .pagination .pagination-pager:active, .pagination .pagination-pager.active,
  .pagination p > a:hover,
  .pagination p > a:focus,
  .pagination p > a:active,
  .pagination p > a.active {
    text-decoration: underline; }

.pagination .arrow {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px; }
  .pagination .arrow a {
    color: #006a8e; }
    .pagination .arrow a span {
      transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
    .pagination .arrow a:hover, .pagination .arrow a:focus, .pagination .arrow a:active {
      color: #3eb1c8; }
      .pagination .arrow a:hover span, .pagination .arrow a:focus span, .pagination .arrow a:active span {
        color: #3eb1c8; }
  .pagination .arrow i:before {
    font-size: 12px;
    font-size: 1.2rem;
    display: inline-block; }
  .pagination .arrow.prev i:before {
    transform: rotate(180deg); }

@media (min-width: 768px) {
  .material-block--txt-img {
    margin-bottom: 120px; } }

.partner-wrap {
  width: 100%;
  position: relative;
  border: 1px solid rgba(160, 160, 160, 0.25);
  margin-bottom: 20px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .partner-wrap img {
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    font-family: "object-fit: contain; object-position: center"; }
  .partner-wrap:hover .partner__overlay, .partner-wrap:focus .partner__overlay, .partner-wrap:active .partner__overlay {
    top: 0; }
  @media (min-width: 768px) {
    .partner-wrap {
      min-height: 200px; } }

.unleash-body-wrap .partner-wrap .partner__overlay {
  top: 0;
  display: block; }

.partner {
  width: 100%;
  height: 100%;
  padding: 0px;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  position: relative;
  overflow: hidden;
  display: flex; }
  @media (min-width: 768px) {
    .partner {
      padding: 20px; } }
  @media (min-width: 1024px) {
    .partner {
      padding: 40px; } }
  @media (min-width: 1200px) {
    .partner {
      padding: 40px 60px; } }

.partner__visual {
  height: 100%;
  max-height: 150px;
  width: 100%;
  object-fit: contain;
  object-position: center;
  padding: 20px; }
  @media (min-width: 768px) {
    .partner__visual {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      height: auto; } }
  @media (min-width: 1200px) {
    .partner__visual {
      padding: 10px; } }

.partner__overlay {
  z-index: 99;
  background-color: #006a8e;
  padding: 20px;
  color: #fff;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  display: none; }
  @media (min-width: 768px) {
    .partner__overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 100%; } }
  .partner__overlay p {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 20px;
    line-height: 2rem;
    font-weight: 600;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    letter-spacing: 0.05em; }
    @media (min-width: 1200px) {
      .partner__overlay p {
        font-size: 14px;
        font-size: 1.4rem; } }
  .partner__overlay i {
    font-size: 11px;
    font-size: 1.1rem; }
  .partner__overlay a {
    display: block;
    color: #fff;
    text-decoration: none; }

/* =================================================
   Patient
   ================================================= */
.flow-grid .skewed-items-grid__item {
  margin-bottom: 30px; }
  .flow-grid .skewed-items-grid__item:last-child {
    margin-bottom: 0; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .flow-grid .skewed-items-grid__item:nth-child(even) .flow-item__title {
      width: calc(100% - 5px); }
      .flow-grid .skewed-items-grid__item:nth-child(even) .flow-item__title:after {
        content: none; }
    .flow-grid .skewed-items-grid__item:nth-child(odd) .flow-item__title {
      margin-left: 0;
      width: calc(100% - 20px); }
      .flow-grid .skewed-items-grid__item:nth-child(odd) .flow-item__title:before {
        content: none; } }
  @media (min-width: 1024px) {
    .flow-grid .skewed-items-grid__item:first-child .flow-item__title, .flow-grid .skewed-items-grid__item:nth-child(4n + 1) .flow-item__title {
      margin-left: 0;
      width: calc(100% - 20px); }
      .flow-grid .skewed-items-grid__item:first-child .flow-item__title:before, .flow-grid .skewed-items-grid__item:nth-child(4n + 1) .flow-item__title:before {
        content: none; }
    .flow-grid .skewed-items-grid__item:nth-child(4n) .flow-item__title {
      width: calc(100% - 5px); }
      .flow-grid .skewed-items-grid__item:nth-child(4n) .flow-item__title:after {
        content: none; } }

.flow-item {
  text-decoration: none;
  display: block; }
  .flow-item p {
    color: #777777;
    /*@include fs(14);
    @include lh(28);*/ }
  .flow-item:hover .flow-item__title,
  .flow-item:hover .flow-item__title:before,
  .flow-item:hover .flow-item__title:after, .flow-item:focus .flow-item__title,
  .flow-item:focus .flow-item__title:before,
  .flow-item:focus .flow-item__title:after, .flow-item:active .flow-item__title,
  .flow-item:active .flow-item__title:before,
  .flow-item:active .flow-item__title:after {
    background-color: #3eb1c8; }

.flow-item__visual {
  width: 100%;
  height: 0;
  padding-bottom: 73.66%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  margin-bottom: 0; }

.flow-item__title,
.psi-item__title,
.tech-nav-item__title {
  background-color: #006a8e;
  position: relative;
  padding: 20px 10px;
  display: inline-block;
  width: 100%;
  color: #fff;
  margin-top: -1px;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 18px;
  line-height: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.15em; }
  @media (min-width: 768px) {
    .flow-item__title,
    .psi-item__title,
    .tech-nav-item__title {
      width: calc(100% - 25px);
      margin-left: 5px;
      margin-bottom: 40px; } }
  @media (min-width: 1200px) {
    .flow-item__title,
    .psi-item__title,
    .tech-nav-item__title {
      font-size: 14px;
      font-size: 1.4rem; } }
  .flow-item__title:before, .flow-item__title:after,
  .psi-item__title:before,
  .tech-nav-item__title:before,
  .psi-item__title:after,
  .tech-nav-item__title:after {
    width: 30px;
    height: 100%;
    background-color: #006a8e;
    position: absolute;
    top: 0;
    transform: skew(-19deg);
    z-index: -1;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
    @media (min-width: 768px) {
      .flow-item__title:before, .flow-item__title:after,
      .psi-item__title:before,
      .tech-nav-item__title:before,
      .psi-item__title:after,
      .tech-nav-item__title:after {
        content: ""; } }
  .flow-item__title:before,
  .psi-item__title:before,
  .tech-nav-item__title:before {
    left: 0;
    margin-left: -15px; }
  .flow-item__title:after,
  .psi-item__title:after,
  .tech-nav-item__title:after {
    right: 0;
    margin-right: -15px; }

/* =================================================
   Concept solution (flow)
   ================================================= */
.concept-solution-heading {
  background-color: #3eb1c8;
  padding: 30px 0;
  width: 100%;
  *zoom: 1;
  margin-bottom: 20px; }
  .concept-solution-heading .heading-with-label__heading {
    margin-bottom: 0; }
  .concept-solution-heading p {
    margin-bottom: 0;
    margin-top: 20px;
    color: #fff; }
    @media (min-width: 768px) {
      .concept-solution-heading p {
        margin-top: 50px; } }
  .concept-solution-heading:before, .concept-solution-heading:after {
    display: table;
    content: " "; }
  .concept-solution-heading:after {
    clear: both; }
  @media (min-width: 768px) {
    .concept-solution-heading {
      margin-bottom: 60px; } }
  @media (min-width: 768px) {
    .concept-solution-heading {
      padding: 50px 0; } }
  @media (min-width: 1024px) {
    .concept-solution-heading {
      padding: 80px 0; } }
  .concept-solution-heading .heading-with-label__label,
  .concept-solution-heading .heading-with-label__heading {
    color: #fff; }

@media (min-width: 768px) {
  .medium--pull-top {
    margin-top: -200px;
    float: left; } }

@media (min-width: 1024px) {
  .medium--pull-top {
    margin-top: -275px; } }

.proces-list {
  counter-reset: procesCounter;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    .proces-list {
      margin-top: 60px;
      margin-bottom: 80px; } }

.proces-listitem {
  counter-increment: procesCounter;
  color: #777777;
  background-image: linear-gradient(to right, #006a8e 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 7px 1px;
  background-repeat: repeat-x;
  margin-bottom: 30px; }
  .proces-listitem:last-child {
    background: none;
    margin-bottom: 0; }
  @media (min-width: 1024px) {
    .proces-listitem {
      margin-left: -20px;
      *zoom: 1; }
      .proces-listitem:before, .proces-listitem:after {
        display: table;
        content: " "; }
      .proces-listitem:after {
        clear: both; }
      .proces-listitem .proces-listitem__visual,
      .proces-listitem .proces-listitem__body {
        float: left;
        padding-left: 20px; }
      .proces-listitem .proces-listitem__visual {
        width: 33.33%; }
      .proces-listitem .proces-listitem__body {
        width: 66.66%; } }

.proces-listitem__visual {
  font-size: 32px;
  font-size: 3.2rem;
  text-align: center;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .proces-listitem__visual {
      font-size: 35px;
      font-size: 3.5rem; } }
  @media (min-width: 1024px) {
    .proces-listitem__visual {
      text-align: left;
      margin-bottom: 0; } }
  .proces-listitem__visual:before {
    content: counter(procesCounter) ".";
    display: inline-block;
    margin-right: 50px; }
  .proces-listitem__visual i {
    color: #006a8e; }

.proces-images .grid, .proces-images .grid--rev, .proces-images .grid--full, .proces-images .grid--p50, .proces-images .grid--p100, .proces-images .grid--p200 {
  margin-bottom: 0; }

.proces-image {
  width: 100%;
  display: block;
  overflow: hidden;
  margin-bottom: 20px; }
  .proces-image:hover .proces-image__image, .proces-image:focus .proces-image__image, .proces-image:active .proces-image__image {
    transform: scale(1.1); }

.proces-image__image {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .proces-image__image img {
    visibility: hidden; }

.choose-us-list li {
  color: #777777;
  font-weight: 600;
  line-height: 32px;
  line-height: 3.2rem; }

/* =================================================
   Surgeon
   ================================================= */
.psi-grid .psi-item__title, .tech-nav-grid .psi-item__title, .psi-grid .tech-nav-item__title, .tech-nav-grid .tech-nav-item__title {
  margin-bottom: 0; }

.psi-grid .skewed-items-grid__item, .tech-nav-grid .skewed-items-grid__item {
  margin-bottom: 15px;
  /*
        @media (min-width: 768px) and (max-width: 1024px); {
          &:nth-child(2n) {

            // background-color: red;
            .psi-item__title {
              //margin-left: 0;

              width: calc(100% - 5px);
              &:after {
                content: none;

              }
            }
          }

          &:nth-child(2n +1) {
            .psi-item__title {

              margin-left: 0;
              width: calc(100% - 20px);
              &:before {
                content: none;

              }
            }
          }

          &:nth-child(3) {
            margin-right: -25px;

            .psi-item__title {
              width: calc(100% - 40px);
            }
          }

          &:nth-child(5) {

            .psi-item__title {
              width: calc(100% - -20px);
            }
          }

          &:nth-child(6) {
            margin-right: -45px;

            .psi-item__title {
              width: calc(100% - 60px);
            }
          }

          &:nth-child(8) {

            .psi-item__title {
              width: calc(100% - -40px);
            }
          }

        }*/ }
  .psi-grid .skewed-items-grid__item:last-child, .tech-nav-grid .skewed-items-grid__item:last-child {
    margin-bottom: 0; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .psi-grid .skewed-items-grid__item:nth-child(odd) .psi-item__title:before, .tech-nav-grid .skewed-items-grid__item:nth-child(odd) .psi-item__title:before, .psi-grid .skewed-items-grid__item:nth-child(odd) .tech-nav-item__title:before, .tech-nav-grid .skewed-items-grid__item:nth-child(odd) .tech-nav-item__title:before {
      content: none; }
    .psi-grid .skewed-items-grid__item:nth-child(even) .psi-item__title:after, .tech-nav-grid .skewed-items-grid__item:nth-child(even) .psi-item__title:after, .psi-grid .skewed-items-grid__item:nth-child(even) .tech-nav-item__title:after, .tech-nav-grid .skewed-items-grid__item:nth-child(even) .tech-nav-item__title:after {
      content: none; } }
  @media (min-width: 1024px) {
    .psi-grid .skewed-items-grid__item:nth-child(3n) .psi-item__title, .tech-nav-grid .skewed-items-grid__item:nth-child(3n) .psi-item__title, .psi-grid .skewed-items-grid__item:nth-child(3n) .tech-nav-item__title, .tech-nav-grid .skewed-items-grid__item:nth-child(3n) .tech-nav-item__title {
      width: calc(100% - 5px); }
      .psi-grid .skewed-items-grid__item:nth-child(3n) .psi-item__title:after, .tech-nav-grid .skewed-items-grid__item:nth-child(3n) .psi-item__title:after, .psi-grid .skewed-items-grid__item:nth-child(3n) .tech-nav-item__title:after, .tech-nav-grid .skewed-items-grid__item:nth-child(3n) .tech-nav-item__title:after {
        content: none; }
    .psi-grid .skewed-items-grid__item:nth-child(3n + 1) .psi-item__title, .tech-nav-grid .skewed-items-grid__item:nth-child(3n + 1) .psi-item__title, .psi-grid .skewed-items-grid__item:nth-child(3n + 1) .tech-nav-item__title, .tech-nav-grid .skewed-items-grid__item:nth-child(3n + 1) .tech-nav-item__title {
      margin-left: 0;
      width: calc(100% - 20px); }
      .psi-grid .skewed-items-grid__item:nth-child(3n + 1) .psi-item__title:before, .tech-nav-grid .skewed-items-grid__item:nth-child(3n + 1) .psi-item__title:before, .psi-grid .skewed-items-grid__item:nth-child(3n + 1) .tech-nav-item__title:before, .tech-nav-grid .skewed-items-grid__item:nth-child(3n + 1) .tech-nav-item__title:before {
        content: none; }
    .psi-grid .skewed-items-grid__item:nth-child(4), .tech-nav-grid .skewed-items-grid__item:nth-child(4) {
      margin-right: -25px; }
      .psi-grid .skewed-items-grid__item:nth-child(4) .psi-item__title, .tech-nav-grid .skewed-items-grid__item:nth-child(4) .psi-item__title, .psi-grid .skewed-items-grid__item:nth-child(4) .tech-nav-item__title, .tech-nav-grid .skewed-items-grid__item:nth-child(4) .tech-nav-item__title {
        width: calc(100% - 40px); }
    .psi-grid .skewed-items-grid__item:nth-child(6) .psi-item__title, .tech-nav-grid .skewed-items-grid__item:nth-child(6) .psi-item__title, .psi-grid .skewed-items-grid__item:nth-child(6) .tech-nav-item__title, .tech-nav-grid .skewed-items-grid__item:nth-child(6) .tech-nav-item__title {
      width: calc(100% - -20px); }
    .psi-grid .skewed-items-grid__item:nth-child(7), .tech-nav-grid .skewed-items-grid__item:nth-child(7) {
      margin-right: -45px; }
      .psi-grid .skewed-items-grid__item:nth-child(7) .psi-item__title, .tech-nav-grid .skewed-items-grid__item:nth-child(7) .psi-item__title, .psi-grid .skewed-items-grid__item:nth-child(7) .tech-nav-item__title, .tech-nav-grid .skewed-items-grid__item:nth-child(7) .tech-nav-item__title {
        width: calc(100% - 60px); }
    .psi-grid .skewed-items-grid__item:nth-child(9) .psi-item__title, .tech-nav-grid .skewed-items-grid__item:nth-child(9) .psi-item__title, .psi-grid .skewed-items-grid__item:nth-child(9) .tech-nav-item__title, .tech-nav-grid .skewed-items-grid__item:nth-child(9) .tech-nav-item__title {
      width: calc(100% - -40px); } }

.psi-item, .tech-nav-item {
  text-decoration: none;
  display: block; }
  .psi-item:hover .psi-item__title, .tech-nav-item:hover .psi-item__title, .psi-item:hover .tech-nav-item__title, .tech-nav-item:hover .tech-nav-item__title,
  .psi-item:hover .psi-item__title:before,
  .tech-nav-item:hover .psi-item__title:before,
  .psi-item:hover .tech-nav-item__title:before,
  .tech-nav-item:hover .tech-nav-item__title:before,
  .psi-item:hover .psi-item__title:after,
  .tech-nav-item:hover .psi-item__title:after,
  .psi-item:hover .tech-nav-item__title:after,
  .tech-nav-item:hover .tech-nav-item__title:after, .psi-item:focus .psi-item__title, .tech-nav-item:focus .psi-item__title, .psi-item:focus .tech-nav-item__title, .tech-nav-item:focus .tech-nav-item__title,
  .psi-item:focus .psi-item__title:before,
  .tech-nav-item:focus .psi-item__title:before,
  .psi-item:focus .tech-nav-item__title:before,
  .tech-nav-item:focus .tech-nav-item__title:before,
  .psi-item:focus .psi-item__title:after,
  .tech-nav-item:focus .psi-item__title:after,
  .psi-item:focus .tech-nav-item__title:after,
  .tech-nav-item:focus .tech-nav-item__title:after, .psi-item:active .psi-item__title, .tech-nav-item:active .psi-item__title, .psi-item:active .tech-nav-item__title, .tech-nav-item:active .tech-nav-item__title,
  .psi-item:active .psi-item__title:before,
  .tech-nav-item:active .psi-item__title:before,
  .psi-item:active .tech-nav-item__title:before,
  .tech-nav-item:active .tech-nav-item__title:before,
  .psi-item:active .psi-item__title:after,
  .tech-nav-item:active .psi-item__title:after,
  .psi-item:active .tech-nav-item__title:after,
  .tech-nav-item:active .tech-nav-item__title:after {
    background-color: #3eb1c8; }

.tech-specs-overview__article {
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .tech-specs-overview__article {
      margin-bottom: 0; } }
  .tech-specs-overview__article.block-link:hover .tech-specs-overview__item__image, .tech-specs-overview__article.block-link:focus .tech-specs-overview__item__image, .tech-specs-overview__article.block-link:active .tech-specs-overview__item__image {
    transform: scale(1.1); }
    .tech-specs-overview__article.block-link:hover .tech-specs-overview__item__image:before, .tech-specs-overview__article.block-link:focus .tech-specs-overview__item__image:before, .tech-specs-overview__article.block-link:active .tech-specs-overview__item__image:before {
      opacity: 0.3; }

.tech-specs-overview__item {
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .tech-specs-overview__item {
      margin-bottom: 0; } }

.tech-specs-overview__item__image-wrap {
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 72.2891566%;
  position: relative;
  margin-bottom: 20px; }

.tech-specs-overview__item__image {
  text-transform: uppercase;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 72.2891566%;
  background-size: cover;
  background-position: center;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  @media (min-width: 768px) {
    .tech-specs-overview__item__image {
      margin-bottom: 40px; } }
  .tech-specs-overview__item__image:before {
    content: "";
    background-color: #006a8e;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

.tech-specs-overview__item__title {
  text-transform: none;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 30px;
  line-height: 3rem;
  color: #006a8e;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .tech-specs-overview__item__title {
      margin-bottom: 30px; } }

.tech-specs-overview__item__description {
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 12px; }

.grid--treatments > .grid__item {
  margin-bottom: 30px; }
  .grid--treatments > .grid__item .img-wrapper {
    margin-bottom: 20px; }
    .grid--treatments > .grid__item .img-wrapper img {
      -o-object-fit: contain;
      object-fit: contain;
      -o-object-position: center;
      object-position: center;
      font-family: "object-fit: contain; object-position: center";
      height: 300px; }
  .grid--treatments > .grid__item h2 {
    margin-bottom: 10px; }
  @media (min-width: 768px) {
    .grid--treatments > .grid__item {
      margin-bottom: 80px; }
      .grid--treatments > .grid__item:nth-child(3n + 1) {
        clear: both; } }

/* =================================================
   Concept solution (surgeon)
   ================================================= */
.spec-buttons .button {
  background-color: #006a8e;
  position: relative;
  clear: none;
  margin-right: 20px;
  float: none;
  padding: 10px;
  display: inline-block;
  margin-left: 5px;
  color: #fff;
  margin-top: -1px;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  margin-bottom: 40px;
  text-align: center;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.15em; }
  .spec-buttons .button:hover, .spec-buttons .button:focus, .spec-buttons .button:active {
    background-color: #3eb1c8; }
    .spec-buttons .button:hover:after, .spec-buttons .button:hover:before, .spec-buttons .button:focus:after, .spec-buttons .button:focus:before, .spec-buttons .button:active:after, .spec-buttons .button:active:before {
      background-color: #3eb1c8; }
  @media (min-width: 1200px) {
    .spec-buttons .button {
      padding: 15px;
      font-size: 14px;
      font-size: 1.4rem; } }
  .spec-buttons .button:first-child:before {
    content: none; }
  .spec-buttons .button:last-child:after {
    content: none; }
  .spec-buttons .button:before, .spec-buttons .button:after {
    content: "";
    width: 20px;
    height: 100%;
    background-color: #006a8e;
    position: absolute;
    top: 0;
    transform: skew(-20deg);
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .spec-buttons .button:before {
    left: 0;
    margin-left: -10px; }
  .spec-buttons .button:after {
    right: 0;
    margin-right: -10px; }

/* =================================================
   Tech
   ================================================= */
.tech-nav-grid {
  margin-bottom: 40px; }

.tech-nav-item {
  text-transform: uppercase; }

.tech-nav-item__title {
  padding: 20px;
  line-height: 10px;
  line-height: 1rem; }

/* =================================================
   Team
   ================================================= */
@media (min-width: 768px) {
  .team-grid > .grid__item:nth-child(2n + 1) {
    clear: both; } }

@media (min-width: 1024px) {
  .team-grid > .grid__item:nth-child(2n + 1) {
    clear: none; }
  .team-grid > .grid__item:nth-child(3n + 1) {
    clear: both; } }

.team-member {
  width: 100%;
  margin-bottom: 40px; }

.team-member__visual {
  height: 300px;
  background-size: cover;
  background-position: center;
  width: 100%;
  margin-bottom: 20px; }

.team-member__name {
  color: #006a8e;
  font-family: 'TrebuchetMS', Helvetica, Arial, Verdana, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5em;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  font-size: 24px;
  font-size: 2.4rem; }

.team-member__visual--placeholder {
  background-color: #f8f8f8;
  position: relative; }
  .team-member__visual--placeholder i {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    font-size: 5rem;
    color: #b9b9b9; }
    @media (min-width: 480px) {
      .team-member__visual--placeholder i {
        font-size: 80px;
        font-size: 8rem; } }

.team-expertises {
  list-style: none;
  margin-left: 0; }
  .team-expertises li {
    display: block; }
    .team-expertises li span {
      display: inline-block;
      vertical-align: top; }

.expertise__name {
  font-family: 'TrebuchetMS', Helvetica, Arial, Verdana, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5em;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  font-size: 24px;
  font-size: 2.4rem;
  color: #006a8e;
  margin-right: 40px; }

.expertise__person {
  color: #a0a0a0;
  padding-top: 10px; }
  .expertise__person p {
    margin-bottom: 10px; }

/* =================================================
   News
   ================================================= */
@media (min-width: 768px) {
  .grid--news > .grid__item:nth-child(3n + 1) {
    clear: both; } }

/* =================================================
   News detail
   ================================================= */
.news-detail__navigation {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 0;
  padding: 0 40px;
  margin-bottom: 20px;
  text-align: center; }
  @media (min-width: 768px) {
    .news-detail__navigation {
      text-align: left;
      justify-content: space-between;
      margin-bottom: 80px; } }
  .news-detail__navigation li {
    flex-shrink: 0;
    width: 100%;
    float: none;
    margin-bottom: 20px;
    display: block; }
    .news-detail__navigation li:first-child {
      margin-left: -0px;
      order: 2; }
      @media (min-width: 768px) {
        .news-detail__navigation li:first-child {
          order: initial; } }
    .news-detail__navigation li:last-child {
      margin-left: -0px; }
    @media (min-width: 768px) {
      .news-detail__navigation li {
        width: auto; } }

.news-detail__navigation__overview-link:after {
  content: none; }

/* =================================================
   Contact
   ================================================= */
.static-map-wrapper {
  margin-bottom: 30px; }

.contact-info {
  margin-left: auto;
  margin-right: auto;
  display: inline-block; }
  .contact-info ul {
    text-align: left;
    display: inline-block;
    margin-right: 40px;
    list-style: none;
    margin-left: 0; }
    .contact-info ul a {
      color: #006a8e; }
      .contact-info ul a:hover, .contact-info ul a:focus, .contact-info ul a:active {
        color: #3eb1c8; }
    .contact-info ul:first-child a {
      text-decoration: none; }

.directions-link-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  *zoom: 1;
  flex-wrap: wrap;
  color: #006a8e;
  margin-bottom: 20px; }
  .directions-link-wrap:before, .directions-link-wrap:after {
    display: table;
    content: " "; }
  .directions-link-wrap:after {
    clear: both; }

.directions-link__visual {
  /*  width: 100%;
    height: 0;*/
  display: inline-block;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-position-y: 0;
  margin-bottom: 0;
  margin-right: 20px;
  width: 80px;
  height: 80px;
  position: relative; }
  .directions-link__visual:after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #3eb1c8;
    opacity: 0;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
    content: ""; }
  .directions-link__visual:hover:after, .directions-link__visual:focus:after, .directions-link__visual:active:after {
    opacity: 0.25; }

.directions-link__button {
  letter-spacing: 0.15em;
  color: #3eb1c8;
  margin-bottom: 0;
  color: inherit;
  text-decoration: none;
  background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), linear-gradient(#3eb1c8, #3eb1c8);
  background-size: 0.05em 1px, 0.05em 1px, 1px 1px;
  background-repeat: no-repeat, no-repeat, repeat-x;
  text-shadow: 0.03em 0 #fff, -0.03em 0 #fff, 0 0.03em #fff, 0 -0.03em #fff, 0.06em 0 #fff, -0.06em 0 #fff, 0.09em 0 #fff, -0.09em 0 #fff, 0.12em 0 #fff, -0.12em 0 #fff, 0.15em 0 #fff, -0.15em 0 #fff;
  background-position: 0 95%, 100% 95%;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 24px;
  line-height: 2.4rem;
  font-weight: 600; }
  .directions-link__button::selection {
    text-shadow: 0.03em 0 #ddf1f5, -0.03em 0 #ddf1f5, 0 0.03em #ddf1f5, 0 -0.03em #ddf1f5, 0.06em 0 #ddf1f5, -0.06em 0 #ddf1f5, 0.09em 0 #ddf1f5, -0.09em 0 #ddf1f5, 0.12em 0 #ddf1f5, -0.12em 0 #ddf1f5, 0.15em 0 #ddf1f5, -0.15em 0 #ddf1f5;
    background: #ddf1f5; }
  .directions-link__button:before, .directions-link__button:after, .directions-link__button *, .directions-link__button *:before, .directions-link__button *:after {
    text-shadow: none; }
  .directions-link__button:visited {
    color: inherit; }

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm; }
  html {
    font-size: 10px; }
  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit; }
  a,
  a:visited {
    text-decoration: underline; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  .page-footer,
  .main-nav {
    display: none; }
  .medium--one-half {
    width: 50%; }
  .medium--two-thirds {
    width: 66.66%; }
  .medium--one-third {
    width: 33.33%; }
  .medium--three-quarters {
    width: 75%; }
  .medium--one-quarter {
    width: 25%; } }
