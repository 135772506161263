@font-face {
  font-family: 'Optima';
  src: url('../fonts/optima/Optima-Regular.eot');
  src: url('../fonts/optima/Optima-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/optima/Optima-Regular.woff2') format('woff2'),
  url('../fonts/optima/Optima-Regular.woff') format('woff'),
  url('../fonts/optima/Optima-Regular.ttf') format('truetype'),
  url('../fonts/optima/Optima-Regular.svg#Optima-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TrebuchetMS';
  src: url('../fonts/trebuchet/TrebuchetMS.eot');
  src: url('../fonts/trebuchet/TrebuchetMS.eot?#iefix') format('embedded-opentype'),
  url('../fonts/trebuchet/TrebuchetMS.woff2') format('woff2'),
  url('../fonts/trebuchet/TrebuchetMS.woff') format('woff'),
  url('../fonts/trebuchet/TrebuchetMS.ttf') format('truetype'),
  url('../fonts/trebuchet/TrebuchetMS.svg#TrebuchetMS') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*============================================================================
  TYPOGRAPHY
==============================================================================*/

// Font Stacks
@mixin default-font($size: 16, $weight: 300, $lineHeight: 1.5em) {
  font-family: 'TrebuchetMS', Helvetica, Arial, Verdana, sans-serif;
  @include fs($size);
  line-height: $lineHeight;
  font-weight: $weight;
  @include optimize-font;
}

@mixin display-font() {
  font-family: 'Optima', Museo Sans, Helvetica, Arial, Verdana, sans-serif;
  font-weight: 300;
}


// Body

p {
  @include lh(26);
  margin-bottom: 1.5em;
}

strong {
  font-weight: 600;
  font-size: inherit;
}

em {
  font-style: italic;
  font-weight: inherit;
}

a {
  font-weight: inherit; font-style: inherit;
  color: $linkColor;
  transition: $transitionDefault;
  text-decoration: underline;
  text-decoration-skip: ink;
  // @include smart-underline; cross browser text-decoration skip

  &:hover, &:focus {
    color: $linkColorHover;
  }

  &:focus, &:active {
    outline: 0;
  }

  &.td-n {
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}

/*a[href^=tel]{
  text-decoration:none;
  &:hover, &:focus {
    text-decoration: underline;
  }
}*/

a:not([href]) {
  color: $black; text-decoration: none;
  &:hover, &:focus {
  }
}

img {
  max-width: 100%; height: auto; display: inline-block; vertical-align: middle;
}


// Headings

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  @include display-font;
  @include optimize-font;
  color: $black;
  line-height: 1.2em;
  margin: 0 0 0.8em;

  &.heading--accent {
    color: $accent;
  }

  a {
    text-decoration: none;
    outline: 0; font-weight: inherit;
    color: $accent;

    &:hover, &:focus {
      color: $accentHover;
    }
  }
}

h1, .h1 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 28px, 56px);
  @include fluid-type(line-height, $mediumSize, $largerSize, 32px, 64px);
  font-weight: 200;
}

h2, .h2 {
  @include default-font();
  @include fluid-type(font-size, $mediumSize, $largerSize, 26px, 36px);

  color: $darkBlue;
}

h3, .h3 {
  @include fluid-type(font-size, $mediumSize, $largeSize, 26px, 30px);

}

h4, .h4 {
  @include fs(16);
}

h5, .h5 {  }

h6, .h6 {  }

.v-center-heading-tov-visual {


  @media #{$medium} {
    width: 100%;
    position: relative;
    height: 0;
    padding-bottom: 73%;
    display: block;

    .v-center-heading-tov-visual__item {
      position: absolute;
      top: 50%;transform: translateY(-50%);


    }

  }



}

.heading-with-label {



}
.heading-with-label__label {
  text-transform: uppercase;
  @include fs(13);
  letter-spacing: 0.15em;
  @include lh(19);
  color: $gray;
  margin-bottom: 20px;
}
.heading-with-label__heading {
  color: $black;

}



// Lists

ul, ol {
  margin: 0 0 1.5em 16px;

  li {
    line-height: 1.5em;
    padding: 0;
  }
}

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0;
}

ul {
  list-style: disc;

  ul {
    list-style: circle;
  }

  &.lined {
    @extend .unstyled;
    li {
      border-bottom: 1px solid #E3E3E3; padding: 5px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0; border-bottom: none;
      }
    }
  }
}

ol {
  list-style: decimal;
}

.unstyled, nav ul {
  margin: 0 0 1.5em; padding: 0; list-style: none;

  ul {
    list-style: none;
  }

  li {
    list-style: none; list-style-image: none;
    padding: 0; background: none;
  }
}

nav ul {
  @include clearfix;
}

dl {
  margin: 0 0 1.5em;
}

dt, dd {
  line-height: 1.5em;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5em;
}

.heading-shadow {
  text-shadow: 0 5px 10px $black;
}

// Misc

hr {
  display: block; height: 1px;
  border: 0; border-top: 1px solid $gray; margin: 50px 0;
}

blockquote {
  line-height: inherit;
  padding-left: 20px;
  border-left: 1px solid $gray;
  margin-bottom: 1.5em;
  @include fs(20);
  font-style: italic;

  p {
    margin-bottom: 0.5em;
  }

  cite {
    @include fs(14);
  }

  @media #{$medium} {
    padding-left: 40px;
  }
}

@font-face {
  font-family: 'esign';
  src:  url('../fonts/esign.eot?vbjx8w');
  src:  url('../fonts/esign.eot?vbjx8w#iefix') format('embedded-opentype'),
  url('../fonts/esign.ttf?vbjx8w') format('truetype'),
  url('../fonts/esign.woff?vbjx8w') format('woff'),
  url('../fonts/esign.svg?vbjx8w#esign') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"], .icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'esign' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-arrow-up:before {
  content: "\e914";
}
.icon-arrow-round:before {
  content: "\e915";
}
.icon-fb:before {
  content: "\e916";
}
.icon-ln:before {
  content: "\e917";
}
.icon-long-arrow:before {
  content: "\e918";
}
.icon-play:before {
  content: "\e919";
}
.icon-plus:before {
  content: "\e91a";
}
.icon-scan:before {
  content: "\e91b";
}
.icon-twitter:before {
  content: "\e91c";
}
.icon-surgeon:before {
  content: "\e91d";
}
.icon-short-arrow:before {
  content: "\e91e";
}
.icon-surgery:before {
  content: "\e91f";
}

.icon-syringe:before {
  content: "\e920";
}
.icon-talk:before {
  content: "\e921";
}
.icon-toothbrush:before {
  content: "\e922";
}
.icon-visit:before {
  content: "\e923";
}
.icon-dental:before {
  content: "\e924";
}
.icon-design:before {
  content: "\e925";
}
.icon-parameters:before {
  content: "\e926";
}
.icon-pills:before {
  content: "\e927";
}
.icon-person:before {
  content: "\e928";
}
.icon-facebook:before {
  content: "\ea91";
}
.icon-linkedin:before {
  content: "\eacb";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-linkedin:before {
  content: "\eaca";
}
